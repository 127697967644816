import React from 'react';
import PropTypes from 'prop-types';
import StatusSuccess from '../../assets/icons/checkmark.svg';
import StatusError from '../../assets/icons/cross_error.svg';
import Interrogation from '../../assets/images/interrogacion.svg';
import InputBase from './Input/InputBase';

import Box from './Box';
import Label from './Typography/Label';
import MyTooltip from './MyTooltip';
import { Copy } from './index';

const CouponInput = ({
  name,
  type = 'text',
  label,
  placeholder,
  value,
  onChange,
  disabled,
  error,
  borderColor = 'lightGray',
  borderRadius = 'input',
  success,
  size = 'full',
  tooltip,
  onClick,
}) => {
  return (
    <Box>
      <Box display="flex">
        {label && (
          <Label htmlFor={name}>
            <Copy lineHeight="1.8">{label}</Copy>
          </Label>
        )}
        {tooltip && (
          <MyTooltip text={tooltip}>
            <Box tag="img" src={Interrogation} marginLeft="small" />
          </MyTooltip>
        )}
      </Box>
      <Box position="relative">
        <InputBase
          tag="input"
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          size={size}
          error={error}
          disabled={disabled}
          borderRadius={borderRadius}
          borderColor={success ? (error ? 'red' : 'statusOk') : borderColor}
          success={success}
          tooltip={tooltip}
        />
        {(success || error) && (
          <Box
            tag="img"
            src={(success && StatusSuccess) || (error && StatusError)}
            position="absolute"
            top="20px"
            right="20px"
            onClick={error && onClick}
          />
        )}
      </Box>
    </Box>
  );
};

CouponInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  success: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
};

export default CouponInput;
