export const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL;
export const gmailApiClient = process.env.REACT_APP_GOOGLE_API_CLIENT;
export const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
export const BASE_URL = window.location.origin;
export const isEarlyWithdrawalEnv = process.env.REACT_APP_IS_EARLY_WITHDRAWAL;
export const percentageInvested = process.env.REACT_APP_PERCENTAGE_INVESTED;
export const simulatorProjects = process.env.REACT_APP_SIMULATOR_PROJECTS;
export const featuredProjects = process.env.REACT_APP_FEATURED_PROJECTS;
export const mercadoPago = process.env.REACT_APP_MERCADO_PAGO;
export const featuredProjectsMeli =
  process.env.REACT_APP_FEATURED_PROJECTS_MELI;
export const perpetualRent = process.env.REACT_APP_PERPETUAL_RENT;
export const criptoProject = process.env.REACT_APP_CRIPTO_PROJECTS;
export const miamiProject = process.env.REACT_APP_MIAMI_PROJECT;
export const clientId = process.env.REACT_APP_CLIENT_ID;
export const flowId = process.env.REACT_APP_FLOW_ID;
export const flowIdPep = process.env.REACT_APP_FLOW_ID_TWO;
export const chatPdfKey = process.env.REACT_APP_CHAT_PDF_API_KEY;
export const calendlyUrlUsd = process.env.REACT_APP_CALENDLY_USD;
export const calendlyUrlArs = process.env.REACT_APP_CALENDLY_ARS;
export const minInvestArs = process.env.REACT_APP_MIN_INVEST_ARS;
export const minInvestUsd = process.env.REACT_APP_MIN_INVEST_USD;
export const flowMetaMx = process.env.REACT_APP_FLOW_META_MX;
export const flowMetaMxPep = process.env.REACT_APP_FLOW_META_MX_PEP;
export const apiHeaderKeyNx = process.env.REACT_APP_API_HEADER_KEY_NX;
export const apiPartnersMX = process.env.REACT_APP_PARTNERS_MX;
export const apiPartnersAR = process.env.REACT_APP_PARTNERS_AR;
