import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useGetCountry from '../hooks/useGetCountry';
import useAuthRecoil from '../hooks/useAuthRecoil';
import { useTranslation } from 'react-i18next';

const CountryContext = createContext();

export const useCountry = () => useContext(CountryContext);

export const CountryProvider = ({ children }) => {
  const [currencyByCountry, setCurrencyByCountry] = useState([]);
  const [countryUser, setCountryUser] = useState(null);
  const { getCountry, data } = useGetCountry();
  const { i18n } = useTranslation();

  const [countryPrefix, setCountryPrefix] = useState(
    localStorage.getItem('country') || '',
  );

  const {
    auth: { user, isAuth },
  } = useAuthRecoil();

  const countryPrefixes = ['/ar', '/mx'];

  useEffect(() => {
    if (isAuth) {
      setCountryPrefix('/' + user?.partnerCountry);
      localStorage.setItem('country', '/' + user?.partnerCountry);
      i18n.changeLanguage(user?.partnerCountry === 'ar' ? 'es-AR' : 'es-MX');
    } else {
      const patchUrl = '/' + window.location.pathname.split('/')[1];
      localStorage.setItem('country', patchUrl);
      setCountryPrefix(patchUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, setCountryPrefix, user?.partnerCountry]);

  useEffect(() => {
    const patchUrl = '/' + window.location.pathname.split('/')[1];
    const countrySelect = localStorage.getItem('country');

    if (
      !isAuth &&
      countryPrefixes.includes(patchUrl) &&
      (!countrySelect || countrySelect === patchUrl)
    ) {
      localStorage.setItem('country', patchUrl);
      setCountryPrefix(patchUrl);
    } else if (!countrySelect && !isAuth) {
      getCountryPrefix();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryPrefix]);

  const getCountryPrefix = () => {
    getCountry();
  };

  useEffect(() => {
    if (data) {
      const ctr = '/' + data.getCountry.message.toString().toLowerCase();
      const country = countryPrefixes.includes(ctr) ? ctr : '/ar';
      localStorage.setItem('country', country);
      setCountryPrefix(country);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (user?.country?.id === 9) {
      setCountryUser('ar');
      setCurrencyByCountry([
        { value: 1, label: 'USD' },
        { value: 2, label: 'ARS' },
      ]);
    } else if (user?.country?.id === 122) {
      setCountryUser('mx');
      setCurrencyByCountry([{ value: 3, label: 'MXN' }]);
    } else {
      setCountryUser('ar');
      setCurrencyByCountry([
        { value: 1, label: 'USD' },
        { value: 2, label: 'ARS' },
      ]);
    }
  }, [user]);

  return (
    <CountryContext.Provider
      value={{
        countryPrefix,
        setCountryPrefix,
        getCountryPrefix,
        currencyByCountry,
        countryUser,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};

CountryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
