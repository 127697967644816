import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '../SimpleUI';

const SectionLayout = ({
  height = 100,
  backgroundColor = 'xlightGray',
  background = '',
  paddingY = 'none',
  display = 'flex',
  ...props
}) => {
  return (
    <Box
      tag="section"
      backgroundColor={backgroundColor}
      background={background}
      minHeight={{
        mobile: 'auto',
        desktop: `${typeof height === 'number' ? `${height}px` : height}`,
      }}
      paddingY={{ mobile: 'xxlarge', desktop: paddingY }}
      width="100%"
      display={display}
      alignItems="center"
      {...props}
    />
  );
};

SectionLayout.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  background: PropTypes.string,
  paddingY: PropTypes.string,
  display: PropTypes.string,
};

export default SectionLayout;
