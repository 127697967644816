import React from 'react';
import PropTypes from 'prop-types';
import InputBase from './InputBase';

const DateInput = ({
  name,
  label,
  value,
  onChange,
  error,
  disabled,
  size,
  autoFocus,
}) => {
  // Must use setFieldValue

  return (
    <InputBase
      tag="input"
      name={name}
      type="date"
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      disabled={disabled}
      autoFocus={autoFocus}
      size={size}
      color="textBlack"
      borderColor="lightGray"
    />
  );
};

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  autoFocus: PropTypes.bool,
};

// Memo to prevent re-render if any of his props doesnt change.
export default React.memo(DateInput);
