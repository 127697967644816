import React from 'react';
import Benefits from '../NewDashboard/Components/Dass/Benefits';
import EasyAndFast from '../NewDashboard/Components/Dass/EasyAndFast';
import Logo from '../NewDashboard/Components/Dass/Logo';
import PreFooter from '../NewDashboard/Components/Dass/PreFooter';
import WhatIsSimple from '../NewDashboard/Components/Dass/WhatIsSimple';
import WhySimplestate from '../NewDashboard/Components/Dass/WhySimplestate';

const Company = () => {
  return (
    <>
      <Logo />
      <WhatIsSimple />
      <Benefits />
      <WhySimplestate />
      <EasyAndFast />
      <PreFooter />
    </>
  );
};

export default Company;
