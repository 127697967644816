import React from 'react';
import Head from '../components/Head';
import { Box } from '../components/SimpleUI';
import { useCountry } from '../contexts/CountryContext';
import Support from '../components/Support/Support';

const Supports = () => {
  const { countryPrefix } = useCountry();
  const isArg = countryPrefix === '/ar';
  const pageTitle = 'Respaldo';
  const pageDescription = 'Es la página de respaldo';

  return (
    <Head title={pageTitle} description={pageDescription}>
      <Box tag="section" position="relative">
        <Support />
      </Box>
    </Head>
  );
};

export default Supports;
