import React from 'react';
import Head from '../components/Head';
import { Box } from '../components/SimpleUI';
import { useCountry } from '../contexts/CountryContext';
import Company from '../components/Companies/Company';

const Companies = () => {
  const { countryPrefix } = useCountry();
  const isArg = countryPrefix === '/ar';
  const pageTitle = 'Empresas';
  const pageDescription = 'Es la página de empresas';

  return (
    <Head title={pageTitle} description={pageDescription}>
      <Box tag="section" position="relative">
        <Company />
      </Box>
    </Head>
  );
};

export default Companies;
