import { useRecoilState } from 'recoil';
import { notificationState } from '../recoil/notificationMenu';

const useNotificationMenuRecoil = () => {
  const [showNotifications, setShowNotifications] = useRecoilState(
    notificationState
  );

  const toggleNotifications = () => {
    setShowNotifications((prev) => !prev);
  };
  return {
    showNotifications,
    toggleNotifications,
  };
};

export default useNotificationMenuRecoil;
