import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '../SimpleUI';

const BoxContent = ({
  tag,
  children,
  loading,
  loadingHeight,
  flat,
  noContentSpace,
  header,
  headerRight,
  boxShadow = 'standard',
  borderColor,
  borderRadius = 'medium',
  backgroundColor = 'white',
  id,
  padding = 'xxlarge',
  ...restProps
}) => {
  return (
    <Box
      tag={tag}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      loading={loading}
      loadingHeight={loadingHeight}
      flat={flat}
      noContentSpace={noContentSpace}
      header={header}
      headerRight={headerRight}
      boxShadow={boxShadow}
      borderRadius={borderRadius}
      id={id}
      padding={padding}
      {...restProps}
    >
      {children}
    </Box>
  );
};

BoxContent.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  loadingHeight: PropTypes.number,
  flat: PropTypes.string,
  noContentSpace: PropTypes.bool,
  header: PropTypes.node,
  headerRight: PropTypes.node,
  boxShadow: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BoxContent;
