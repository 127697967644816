import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useCountry } from '../contexts/CountryContext';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ isAuth, children, ...rest }) => {
  const { countryPrefix } = useCountry();

  if (!isAuth) {
    return <Redirect to={`${countryPrefix}/cuenta`} />;
  }
  return <Route {...rest}>{children}</Route>;
};

ProtectedRoute.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
