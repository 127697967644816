import React from 'react';
import Box from '../Box';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DropdownItem = ({ children, onClick }) => {
  return (
    <StyledBox tag="li" display="flex">
      <Box
        tag="button"
        width="100%"
        onClick={onClick}
        paddingX="medium"
        cursor="pointer"
        paddingY="small"
      >
        {children}
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;

DropdownItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DropdownItem;
