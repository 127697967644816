import React from 'react';
import { HStack, VStack } from './SimpleUI';
import useDesignUtils from '../hooks/useDesignUtils';
import PropTypes from 'prop-types';

const VHMobileStack = ({ reverse, ...rest }) => {
  const { isMobile } = useDesignUtils();

  const getDirection = () => {
    if (isMobile) {
      if (reverse) {
        return HStack;
      }

      return VStack;
    } else {
      if (reverse) {
        return VStack;
      }

      return HStack;
    }
  };

  const Direction = getDirection();

  return <Direction {...rest} />;
};

VHMobileStack.propTypes = {
  reverse: PropTypes.bool,
};

export default VHMobileStack;
