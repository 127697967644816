import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import styled, { css } from 'styled-components';
import useDesignUtils from '../../../hooks/useDesignUtils';

const VStack = ({
  children,
  tag,
  space,
  marginBottom,
  marginTop,
  padding,
  horizontalCenter,
  ...restProps
}) => {
  const { resolveThemeProps } = useDesignUtils();
  const resolvedSpace = resolveThemeProps('scale', space);

  return (
    <View
      tag={tag}
      display="block"
      resolvedSpace={resolvedSpace}
      marginBottom={marginBottom}
      marginTop={marginTop}
      padding={padding}
      position="relative"
      width="100%"
      textAlign={horizontalCenter ? 'center' : undefined}
      {...restProps}
    >
      {children}
    </View>
  );
};

const View = styled(Box)`
  ${setChildrenSpace}
`;

function setChildrenSpace({ resolvedSpace }) {
  if (resolvedSpace) {
    return css`
      & > :not(style) ~ :not(style) {
        margin-block-start: ${resolvedSpace};
      }
    `;
  }
}

VStack.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alignItems: PropTypes.string,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  tag: PropTypes.string,
  padding: PropTypes.string,
  horizontalCenter: PropTypes.bool,
  justifyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default VStack;
