import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../Box';
import Copy from '../Typography/Copy';

const NavButton = ({ children, active, onClick }) => {
  return (
    <Wrapper
      type="button"
      tag="button"
      padding="medium"
      color={active ? 'purple' : 'black'}
      position="relative"
      active={active}
      onClick={onClick}
      backgroundColor={active ? 'white' : '_lightGray'}
      boxShadow={active ? 'new' : 'none'}
      width="100%"
    >
      <Copy
        fontWeight={active ? 'bold' : 'medium'}
        fontSize="_small"
        color={active ? '_lightGreen' : 'black'}
      >
        {children}
      </Copy>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -3px;
    width: 100%;
    left: 0;
  }
`;

NavButton.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default NavButton;
