import React from 'react';

import { Box, VStack, Copy, PrimaryCard, HStack } from '../../../SimpleUI';
import { Cell, Grid } from '@mollycule/lattice';
import SectionLayout from '../../../Home/SectionLayout';

import SupportExpertsMarketImg from '../../../../assets/images/support-experts-market.svg';
import SupportExpertsSecurityImg from '../../../../assets/images/support-experts-security.svg';
import SupportExpertsDiversificationImg from '../../../../assets/images/support-experts-diversification.svg';
import CompaniesExpertsFinancialEducationImg from '../../../../assets/images/companies-experts-financial-education.svg';
import CompaniesExpertsPriorityAttentionImg from '../../../../assets/images/companies-experts-priority-attention.svg';
import CompaniesExpertsSupportImg from '../../../../assets/images/companies-experts-support.svg';

import useDesignUtils from '../../../../hooks/useDesignUtils';

const WhySimplestate = () => {
  const { isMobile } = useDesignUtils();
  const StackDirection = isMobile ? HStack : VStack;

  const experts = [
    {
      id: 1,
      title: 'Seguridad',
      initialText: 'Solo',
      boldText:
        'trabajamos con desarrolladores World Class o con Track Record ',
      finalText: 'dentro del mercado.',
      cover: SupportExpertsSecurityImg,
    },
    {
      id: 2,
      title: 'Respaldo',
      initialText: 'Contamos con el respado de ',
      boldText: '+100 propiedades ',
      finalText: 'con el objetivo de proteger tu capital y tus ganancias.',
      cover: CompaniesExpertsSupportImg,
    },
    {
      id: 3,
      title: 'Mercado',
      initialText: 'Nuestros proyectos están en el ',
      boldText: 'mercado de Estados Unidos, ',
      finalText:
        'que es el más seguro en inmuebles y tiene los m2 más valorados a nivel mundial.',
      cover: SupportExpertsMarketImg,
    },
    {
      id: 4,
      title: 'Diversificación',
      initialText: 'Sólo ',
      boldText: 'adquirimos un 10% del desarrollo, ',
      finalText: 'con el objetivo de tener diversificados tus ahorros.',
      cover: SupportExpertsDiversificationImg,
    },
    {
      id: 5,
      title: 'Educación financiera',
      initialText: '',
      boldText: 'Webinars y Charlas ',
      finalText: 'de educación financiera.',
      cover: CompaniesExpertsFinancialEducationImg,
    },
    {
      id: 6,
      title: 'Atención prioritaria',
      initialText: 'Vía de contacto ',
      boldText: 'exclusiva',
      finalText: '.',
      cover: CompaniesExpertsPriorityAttentionImg,
    },
  ];

  return (
    <SectionLayout id="whySimpleState" backgroundColor="white">
      <Box
        width="100%"
        paddingX="medium"
        maxWidth="1200px"
        paddingY={{ mobile: 'medium', desktop: 'xxxxlarge' }}
        marginY={{ mobile: 'medium', desktop: 'xxxxlarge' }}
        margin="auto"
      >
        <Grid
          width="100%"
          maxWidth="1150px"
          mx="auto"
          gap={isMobile ? '0' : '14px'}
          cols={{ md: 12, xs: 12 }}
          alignItems="center"
        >
          <Cell x-span={{ sm: '10', md: '12', xs: '12' }}>
            <Box padding="small" marginY="large" tag="section">
              <Copy
                fontWeight="bold"
                fontSize="bigHeader"
                textAlign={isMobile ? 'center' : 'left'}
              >
                ¿Por que Simplestate?
              </Copy>
            </Box>
          </Cell>
          {experts.map((article, i) => {
            return (
              <Cell
                x-span={{ xs: '12', md: '4' }}
                key={i}
                margin="10px 10px 10px 10px"
              >
                <Box
                  boxShadow="medium"
                  borderRadius="medium"
                  overflow="hidden"
                  tag="article"
                  verticalAlign="middle"
                  width={isMobile ? '342px' : '353px'}
                  height={
                    isMobile
                      ? i == 2
                        ? '170px'
                        : i == 3
                        ? '130px'
                        : i == 4
                        ? '120px'
                        : i == 5
                        ? '120px'
                        : '155px'
                      : '354px'
                  }
                >
                  <StackDirection
                    space="large"
                    padding={isMobile ? 'large' : 'xlarge'}
                  >
                    <Box
                      tag="img"
                      src={article.cover}
                      width={isMobile ? '65px' : '80px'}
                      height={isMobile ? '65px' : '80px'}
                      className="img-cover"
                    />
                    <Box
                      tag="header"
                      minHeight={isMobile ? '250px' : '251px'}
                      display="inline-block"
                      verticalAlign="middle"
                    >
                      <VStack display="inline-block" verticalAlign="middle">
                        <Copy
                          tag="h2"
                          fontWeight="semibold"
                          fontSize="midSizeTitle"
                          textAlign="left"
                          color="_lightPurple"
                          paddingY={isMobile ? 'xxxsmall' : 'xxsmall'}
                        >
                          {article.title}
                        </Copy>

                        <Copy fontSize="bigCopy" tag="p" textAlign="left">
                          {article?.initialText}{' '}
                          <Copy
                            fontWeight="semibold"
                            fontSize="bigCopy"
                            tag="span"
                          >
                            {article?.boldText}{' '}
                          </Copy>
                          {article?.finalText}
                        </Copy>
                      </VStack>
                    </Box>
                  </StackDirection>
                </Box>
              </Cell>
            );
          })}
        </Grid>
      </Box>
    </SectionLayout>
  );
};

export default WhySimplestate;
