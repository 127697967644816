import Box from './Box';
import Collapsabler from './Collapsabler';
import QuaternaryCard from './Card/Quaternary';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import { FiChevronDown } from 'react-icons/fi';
import { ReactComponent as Caret } from '../../assets/icons/rounded_caret_up.svg';
import { ReactComponent as Plus } from '../../assets/icons/icon_plus.svg';

const Collapse = ({
  children,
  title,
  shadow,
  noSpaces,
  borderRadius,
  iconVariant = 'arrow',
  iconVariantColor = 'green',
  tag,
  background,
}) => {
  const [isOpen, setOpen] = useToggle(false);

  useEffect(() => {
    if (title === 'Proyecto') {
      setOpen(true);
    }
  }, [setOpen, title]);

  return (
    <QuaternaryCard
      noContentSpace
      boxShadow={shadow}
      borderRadius={borderRadius}
      backgroundColor={background}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        paddingY={noSpaces ? 'none' : 'medium'}
        paddingX={noSpaces ? 'none' : 'large'}
        onClick={setOpen}
        tag={tag}
        $fontSize="16px"
      >
        {typeof title === 'function' ? title() : title}
        {iconVariant === 'arrow' && (
          <IconWrapper isOpen={isOpen}>
            <FiChevronDown width={24} />
          </IconWrapper>
        )}
        {iconVariant === 'plus' && (
          <Box isOpen={isOpen} height="24px">
            {isOpen ? (
              <Box
                height="4px"
                backgroundColor={iconVariantColor}
                width="16px"
                marginTop="small"
              ></Box>
            ) : (
              <Box>
                <Plus width={16} height={16} color={iconVariantColor} />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Collapsabler isOpen={isOpen} noSpaces>
        <Box padding={noSpaces ? 'none' : 'large'} paddingTop="none">
          {children}
        </Box>
      </Collapsabler>
    </QuaternaryCard>
  );
};

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noSpaces: PropTypes.bool,
  borderRadius: PropTypes.string,
  iconVariant: PropTypes.oneOf(['arrow', 'plus']),
  iconVariantColor: PropTypes.string,
  tag: PropTypes.string,
  background: PropTypes.string,
};

const IconWrapper = styled.div`
  svg {
    transition: all 200ms ease;
    transform: rotate(${({ isOpen }) => (isOpen ? '180' : '0')}deg);
  }
`;

export default Collapse;
