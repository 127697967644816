import React from 'react';
import PropTypes from 'prop-types';
import HStack from '../Stack/HStack';
import Box from '../Box';
import useDesignUtils from '../../../hooks/useDesignUtils';
import { isObject } from 'lodash';
import { resolveFontFamilyByWeight } from '../../../helpers/index';

const NewButtonBase = ({
  type,
  children,
  iconStart,
  iconEnd,
  loading,
  size,
  height = 'auto',
  disabled,
  backgroundColor,
  color = 'white',
  textDecoration,
  borderColor,
  borderRadius = 'large',
  horizontalCenter = true,
  tag = 'button',
  href,
  noSpaces,
  hover,
  borderWidth = '1px',
  fontSize,
  ...restProps
}) => {
  const { theme } = useDesignUtils();

  const responsiveFontSizes = fontSize
    ? theme.fontSizes[fontSize]
    : theme.fontSizes['button'];
  const isDisabled = isObject(disabled)
    ? Object.keys(disabled).length > 0
    : disabled;

  const disabledButtonType = {
    primary: {
      background: 'btnDisabled',
      color: 'black',
      border: 'btnDisabled',
    },
    secondary: {
      background: 'btnDisabled',
      color: 'black',
      border: 'btnDisabled',
    },
    tertiary: {
      background: 'white',
      color: '_midLightGray',
      border: 'transparent',
    },
  };
  const colorDisabledVariant =
    disabledButtonType[restProps.buttonDesign] || disabledButtonType['primary'];

  return (
    <Box
      type={tag !== 'a' ? type : undefined}
      tag={tag}
      borderRadius={borderRadius}
      width={size === 'full' ? '100%' : size}
      paddingX={noSpaces ? 'none' : 'midMedium'}
      paddingY={noSpaces ? 'none' : 'midMedium'}
      borderWidth={borderWidth}
      borderStyle="solid"
      backgroundColor={
        isDisabled ? colorDisabledVariant.background : backgroundColor
      }
      $color={isDisabled ? colorDisabledVariant.color : color}
      $fontWeight="medium"
      $fontFamily={resolveFontFamilyByWeight()}
      $fontSize={responsiveFontSizes}
      textDecoration={isDisabled ? 'none' : textDecoration}
      borderColor={isDisabled ? colorDisabledVariant.border : borderColor}
      disabled={isDisabled}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      role="button"
      href={tag === 'a' ? href : undefined}
      height={height}
      hover={
        isDisabled
          ? { backgroundColor: colorDisabledVariant.background }
          : hover
      }
      transition="all 200ms ease"
      {...restProps}
    >
      <HStack
        space="small"
        alignItems="center"
        horizontalCenter={horizontalCenter}
      >
        {iconStart && <Box display="flex">{iconStart}</Box>}
        <span>{loading ? 'Cargando...' : children}</span>
        {iconEnd && <Box display="flex">{iconEnd}</Box>}
      </HStack>
    </Box>
  );
};

NewButtonBase.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  loading: PropTypes.bool,
  size: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  textDecoration: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  tag: PropTypes.string,
  href: PropTypes.string,
  horizontalCenter: PropTypes.bool,
  noSpaces: PropTypes.bool,
  hover: PropTypes.object,
  borderWidth: PropTypes.string,
  fontSize: PropTypes.string,
};

export default NewButtonBase;
