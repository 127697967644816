import React, { useEffect, useState } from 'react';
import EmptyImage from '../assets/images/empty-notificaciones.jpg';
import useGetNotifications from '../hooks/useGetNotifications';
import useNotificationMenuRecoil from '../hooks/useNotificationMenuRecoil';
import NotificationsData from './NotificationsData';
import {
  Box,
  Nav,
  NavButton,
  NavItem,
  OffCanvas,
  SubTitle,
  TabContent,
  VStack,
} from './SimpleUI';

const NotificationsContainer = () => {
  const {
    showNotifications,
    toggleNotifications,
  } = useNotificationMenuRecoil();
  const [activeTab, setActiveTab] = useState(0);
  const { data, refetch } = useGetNotifications();

  useEffect(() => {
    if (showNotifications) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotifications]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderEmpty = () => {
    return (
      <>
        <Box
          tag="img"
          src={EmptyImage}
          alt="Empty notifications"
          maxWidth="130px"
          display="block"
          marginX="auto"
          marginTop="xlarge"
          marginBottom="large"
        />
        <SubTitle fontWeight="medium" textAlign="center">
          No hay notificaciones
        </SubTitle>
      </>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 1: {
        return renderEmpty();
      }

      default: {
        return (
          <VStack space="medium">
            {data?.getNotifications?.notifications.length > 0
              ? data?.getNotifications?.notifications.map((item, key) => (
                  <NotificationsData
                    key={key}
                    item={item}
                    toggleNotifications={toggleNotifications}
                  />
                ))
              : renderEmpty()}
          </VStack>
        );
      }
    }
  };

  return (
    <OffCanvas
      isOpen={showNotifications}
      onClick={toggleNotifications}
      title="Notificaciones"
    >
      {/* <Nav spaceBetween>
        {['Notificaciones'].map((link, index) => (
          <NavItem fullWidth key={index}>
            <NavButton
              active={activeTab === index}
              onClick={() => toggleTab(index)}
            >
              {link}
            </NavButton>
          </NavItem>
        ))}
      </Nav> */}
      <TabContent>{renderTabContent()}</TabContent>
    </OffCanvas>
  );
};

export default NotificationsContainer;
