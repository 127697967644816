import PropTypes from 'prop-types';
import React from 'react';
import SelectBase from './SelectBase';

const SelectBig = ({
  options,
  name,
  value,
  label,
  onChange,
  error,
  size,
  placeholder,
  loading,
}) => {
  return (
    <SelectBase
      name={name}
      value={value}
      label={label}
      onChange={onChange}
      error={error}
      size={size}
      placeholder={placeholder}
      loading={loading}
      options={options}
      variant="secondary"
    />
  );
};

SelectBig.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
};

export default React.memo(SelectBig);
