import React from 'react';
import CardBase from './CardBase';
import PropTypes from 'prop-types';

const Primary = ({
  tag,
  children,
  loading,
  loadingHeight,
  flat,
  noContentSpace,
  header,
  headerRight,
  boxShadow,
  borderColor = 'lightGray',
  borderRadius,
}) => {
  return (
    <CardBase
      tag={tag}
      borderColor={borderColor}
      backgroundColor="white"
      loading={loading}
      loadingHeight={loadingHeight}
      flat={flat}
      noContentSpace={noContentSpace}
      header={header}
      headerRight={headerRight}
      boxShadow={boxShadow}
      borderRadius={borderRadius}
    >
      {children}
    </CardBase>
  );
};

Primary.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  loadingHeight: PropTypes.number,
  flat: PropTypes.string,
  noContentSpace: PropTypes.bool,
  header: PropTypes.node,
  headerRight: PropTypes.node,
  boxShadow: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
};

export default Primary;
