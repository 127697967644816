import React from "react";
import PropTypes from "prop-types";
import BaseTypography from "./Base";

const BigTitle = ({
  tag = "h1",
  textAlign,
  textTransform,
  color,
  children,
}) => {
  return (
    <BaseTypography
      tag={tag}
      textAlign={textAlign}
      textTransform={textTransform}
      color={color}
      fontSize="bigTitle"
      fontWeight="bold"
    >
      {children}
    </BaseTypography>
  );
};

BigTitle.propTypes = {
  tag: PropTypes.string,
  textAlign: PropTypes.string,
  textTransform: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
};

export default BigTitle;
