import React from 'react';
import { Cell, Grid } from '@mollycule/lattice';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';
import { Box, Copy, HStack, VStack } from '../../../SimpleUI';

const steps = [
  {
    number: '01',
    title: 'Registrate',
    text: 'Creá tu cuenta en Simplestate en sólo unos minutos.',
  },
  {
    number: '02',
    title: 'Creá tu inversión',
    text: 'Seleccioná la moneda y el monto qué queres invertir.',
  },
  {
    number: '03',
    title: 'Confirmá tu inversión',
    text:
      'Aceptá la transacción y listo, comenzá a generar ingresos mensuales.',
  },
];

const EasyAndFast = () => {
  const { isMobile } = useDesignUtils();

  return (
    <SectionLayout
      paddingY="small"
      paddingX="large"
      backgroundColor="white"
      maxWidth="1200px"
      marginX="auto"
      marginBottom="xxxxxxlarge"
      flexDirection="column"
      alignItems="start"
      gap="xxlarge"
    >
      <Copy fontWeight="bold" fontSize="bigHeader">
        Invertir en Simplestate
        <br />
        es fácil y rápido
      </Copy>
      <Grid
        width="100%"
        mx="auto"
        gap={{ xs: '40px', md: '52px' }}
        cols={12}
        alignItems="center"
      >
        {steps.map(({ number, title, text }) => (
          <Cell
            x-span={{ xs: '12', md: '4' }}
            key={number}
            width={{ xs: 'calc(100vw - 48px)', md: 'auto' }}
          >
            <Box
              borderStyle="solid"
              borderWidth="1.5px"
              borderColor="primary"
              borderRadius="medium"
              padding="large"
              paddingTop="xxlarge"
              position="relative"
              marginTop={{ mobile: 'xlarge', desktop: 'xxxxxlarge' }}
            >
              <Copy
                fontSize="companiesPreFooterSectionTitle"
                tag="span"
                position="absolute"
                backgroundColor="secondary"
                padding={{ mobile: 'medium', desktop: 'large' }}
                borderRadius="full"
                color="secondaryDark"
                top={{ mobile: '-45px', desktop: '-65px' }}
                display="inline-block"
                height={{ mobile: '68px', desktop: '100px' }}
                width={{ mobile: '68px', desktop: '100px' }}
                textAlign="center"
              >
                {number}
              </Copy>
              <Copy tag="h3" fontSize="easyAndFastTitle" fontWeight="bold">
                {title}
              </Copy>
              <Copy tag="h3" fontSize="text16">
                {text}
              </Copy>
            </Box>
          </Cell>
        ))}
      </Grid>
    </SectionLayout>
  );
};
export default EasyAndFast;
