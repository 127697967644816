import React from 'react';

import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';
import { Box, Copy, HStack, VStack } from '../../../SimpleUI';

const WhatIsSimple = () => {
  const { isMobile } = useDesignUtils();

  return (
    <SectionLayout
      paddingY="small"
      paddingX="large"
      backgroundColor="white"
      maxWidth="1200px"
      marginX="auto"
      style={{ marginTop: isMobile ? '-2.5rem' : 'inherit' }}
    >
      <Box
        width="100%"
        maxWidth="1200px"
        padding={{ mobile: 'large', desktop: 'xxxlarge' }}
        marginBottom={{ mobile: 'small', desktop: 'xxxxxlarge' }}
        margin="auto"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="_lightPurple"
        borderRadius="medium"
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        gap="large"
        alignItems={{ mobile: 'start', desktop: 'center' }}
        justifyContent="space-between"
      >
        <Copy tag="h1" fontSize="bigHeader" fontWeight="bold">
          ¿Qué es <br />
          Simplestate?
        </Copy>
        <Copy
          tag="p"
          fontSize="midSubTitleMeli"
          maxWidth={{ mobile: '100%', desktop: '55%' }}
        >
          Somos la plataforma líder, desde 2019, en inversiones en propiedades
          de Latam. Diseñada para ayudarte a maximizar tus ingresos de manera
          segura y confiable.
        </Copy>
      </Box>
    </SectionLayout>
  );
};
export default WhatIsSimple;
