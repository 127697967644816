import React from 'react';
import Head from '../Head';
import Invest from '../NewDashboard/Components/Support/Invest';
import WhySimplestate from '../NewDashboard/Components/Support/WhySimplestate';
import HowWeSelect from '../NewDashboard/Components/Support/HowWeSelect';
import Projects from '../NewDashboard/Components/Support/Projects';
import Allies from '../NewDashboard/Components/Support/Allies';
import Media from '../NewDashboard/Components/Support/Media';
import Faqs from '../NewDashboard/Components/Support/Faqs';
import OurTeam from '../NewDashboard/Components/Support/OurTeam';

const Support = () => {
  return (
    <Head
      title="Respaldo"
      description="Conocé los proyectos inmobiliarios disponibles. Ingresá en Simplestate y potenciá tus ahorros con los emprendimientos inmobiliarios"
    >
      <Invest />
      <WhySimplestate />
      <HowWeSelect />
      <Projects />
      <OurTeam />
      <Allies />
      <Media />
      <Faqs />
    </Head>
  );
};

export default Support;
