import React from 'react';
import PropTypes from 'prop-types';
import useDesignUtils from '../../../hooks/useDesignUtils';
import Box from '../Box';
import { resolveFontFamilyByWeight } from '../../../helpers/index';

const BaseTypography = ({
  tag = 'p',
  fontWeight,
  textAlign,
  textTransform = 'none',
  color = 'textBlack',
  fontSize,
  lineHeight,
  ...rest
}) => {
  const { theme } = useDesignUtils();
  const responsiveFontSizes = theme.fontSizes[fontSize];

  return (
    <Box
      tag={tag}
      $fontWeight={fontWeight}
      textAlign={textAlign}
      $textTransform={textTransform}
      $color={color}
      $fontSize={responsiveFontSizes}
      $lineHeight={lineHeight}
      $fontFamily={resolveFontFamilyByWeight()}
      {...rest}
    />
  );
};

BaseTypography.propTypes = {
  tag: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textTransform: PropTypes.string,
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string.isRequired,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BaseTypography;
