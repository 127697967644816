import React from 'react';
import { Box, Copy, VStack, HStack } from '../../../SimpleUI';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';
import { useCountry } from '../../../../contexts/CountryContext';

import SupportMobileArImg from '../../../../assets/images/support-mobile-ar.svg';
import SupportMobileMxImg from '../../../../assets/images/support-mobile-mx.svg';
import SupportFlagArgImg from '../../../../assets/images/support-flag-arg.svg';
import SupportFlagStabilityImg from '../../../../assets/images/support-flag-stability.svg';
import SupportFlagUruImg from '../../../../assets/images/support-flag-uru.svg';
import SupportFlagUsaImg from '../../../../assets/images/support-flag-usa.svg';

const WhySimplestate = () => {
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();

  const StackDirection = isMobile ? VStack : HStack;
  const isMx = countryPrefix === '/mx';
  const SupportMobileImg = SupportMobileArImg;

  return (
    <SectionLayout
      backgroundColor="orangeX"
      width="100%"
      display="flex"
      justifyContent="center"
      paddingY="xxxlarge"
    >
      <StackDirection
        alignItems="center"
        justifyContent="space-between"
        gap="xxlarge"
        paddingX={{ mobile: 'xsmall', desktop: 'medium' }}
        marginX={{ mobile: 'xsmall', desktop: 'medium' }}
        maxWidth="1200px"
      >
        {!isMobile && (
          <Box
            tag="img"
            src={SupportMobileImg}
            width={isMx ? '480px' : '470px'}
            height={isMx ? '680px' : '633px'}
            transform="translateY(3rem)"
          />
        )}

        <VStack space={isMobile ? 'small' : 'xsmall'}>
          <Box
            tag="img"
            src={SupportFlagStabilityImg}
            transform={isMobile ? 'translateY(-50%)' : 'translate(-40%, -40%)'}
            position="absolute"
            width={{ mobile: '47px', desktop: '80px' }}
            height={{ mobile: '47px', desktop: '80px' }}
          />
          <Box
            alignItems="center"
            justifyContent="space-evenly"
            boxShadow="medium"
            borderRadius="xxlarge"
            verticalAlign="center"
            borderStyle="solid"
            marginBottom="medium"
            height={{ mobile: '40px', desktop: '70px' }}
          >
            <Copy
              fontWeight="bold"
              fontSize="supportWhyTitle"
              textAlign="center"
            >
              ¿POR QUE SIMPLESTATE?
            </Copy>
          </Box>

          <Box padding="small" marginBottom="xlarge">
            <Copy fontSize="companiesLogoSectionText" textAlign="center">
              Somos la empresa líder en micro inversiones en propiedades de
              LATAM y te contamos cómo lo hacemos
            </Copy>
          </Box>

          <HStack
            justifyContent="space-evenly"
            alignItems="start"
            gap="small"
            rowGap="large"
            flexWrap="wrap"
          >
            <Box
              textAlign="center"
              marginX={{ mobile: 'xxlarge', desktop: 'none' }}
            >
              <Copy
                fontSize="supportPercentPill"
                fontWeight="semibold"
                color="orangeX"
                paddingX="standard"
                paddingY="small"
                display="flex"
                justifyContent="center"
                alignItems="spaceBetween"
                borderRadius="xxlarge"
                marginBottom="small"
                backgroundColor="white"
                width="auto"
                flexGrow="0"
              >
                100% USA
                <Box
                  tag="img"
                  src={SupportFlagUsaImg}
                  width={{ mobile: '18px', desktop: '35px' }}
                  height={{ mobile: '18px', desktop: '35px' }}
                />
              </Copy>

              <Copy fontSize="supportPercentUnderPill" textAlign="center">
                ubicación de las propiedades
              </Copy>
            </Box>
            <Box textAlign="center">
              <Copy
                fontSize="supportPercentPill"
                fontWeight="semibold"
                color="orangeX"
                paddingX="standard"
                paddingY="small"
                display="flex"
                justifyContent="center"
                borderRadius="xxlarge"
                marginBottom="small"
                backgroundColor="white"
              >
                +90% USUARIOS
              </Copy>

              <Copy fontSize="supportPercentUnderPill">
                reinvierte sus ganancias
              </Copy>
            </Box>
            <Box textAlign="center">
              <Copy
                fontSize="supportPercentPill"
                fontWeight="semibold"
                color="orangeX"
                paddingX="standard"
                paddingY="small"
                display="flex"
                justifyContent="center"
                borderRadius="xxlarge"
                marginBottom="small"
                backgroundColor="white"
              >
                +17 MESES
              </Copy>

              <Copy fontSize="supportPercentUnderPill">
                las personas se quedan
                <br />
                en Simplestate
              </Copy>
            </Box>
          </HStack>
        </VStack>
        {isMobile && (
          <Box
            tag="img"
            marginTop="0px"
            src={SupportMobileImg}
            transform="translateY(10%)"
            height="405px"
            display="block"
            style={{ margin: '0 auto' }}
          />
        )}
      </StackDirection>
    </SectionLayout>
  );
};
export default WhySimplestate;
