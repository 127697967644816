import React from 'react';
import Head from '../Head';
import Benefits from '../NewDashboard/Components/Companies/Benefits';
import Logo from '../NewDashboard/Components/Companies/Logo';
import PreFooter from '../NewDashboard/Components/Companies/PreFooter';
import WhySimplestate from '../NewDashboard/Components/Companies/WhySimplestate';

const Company = () => {
  return (
    <Head
      title="Empresas"
      description="Ingresá en Simplestate y potenciá tus ahorros con los emprendimientos inmobiliarios"
    >
      <Logo />
      <Benefits />
      <WhySimplestate />
      <PreFooter />
    </Head>
  );
};

export default Company;
