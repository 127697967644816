import React from 'react';
import PropTypes from 'prop-types';
import Box from './Box';
import SmallCopy from './Typography/SmallCopy';

const Alert = ({ children }) => {
  return (
    <Box
      width="100%"
      padding="medium"
      backgroundColor="lightRed"
      borderRadius="small"
      borderColor="red"
      borderWidth="1px"
      borderStyle="solid"
    >
      <SmallCopy color="white" fontWeight="medium">
        {children}
      </SmallCopy>
    </Box>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Alert;
