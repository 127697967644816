import { theme } from '../theme';
import { useMedia } from 'react-use';

const useDesignUtils = () => {
  const isMobile = useMedia(`(max-width: ${theme.medias.mobile}px)`);
  const isTablet = useMedia(
    `(min-width: ${theme.medias.mobile + 1}px) and (max-width: ${
      theme.medias.tablet
    }px)`,
  );
  const isDesktop = useMedia(`(min-width: ${theme.medias.tablet + 1}px)`);

  const resolveProps = (valueOrMap) => {
    if (!valueOrMap) {
      return undefined;
    }

    if (typeof valueOrMap !== 'object') {
      return valueOrMap;
    }

    if (valueOrMap.mobile && isMobile) {
      return valueOrMap.mobile;
    }
    if (valueOrMap.tablet && isTablet) {
      return valueOrMap.tablet;
    }

    if (valueOrMap.desktop && (isDesktop || isTablet)) {
      return valueOrMap.desktop;
    }
    return undefined;
  };

  const resolveThemeProps = (themeKey, valueOrMap) => {
    if (!valueOrMap) {
      return undefined;
    }

    if (typeof valueOrMap !== 'object') {
      const scaleKey = valueOrMap;
      const scales = theme[themeKey];
      return scales ? scales[scaleKey] : undefined;
    }

    if (valueOrMap.mobile && isMobile) {
      return theme[themeKey][valueOrMap.mobile];
    }
    if (valueOrMap.tablet && isTablet) {
      return theme[themeKey][valueOrMap.tablet];
    }

    if (valueOrMap.desktop && (isDesktop || isTablet)) {
      return theme[themeKey][valueOrMap.desktop];
    }

    return undefined;
  };

  return {
    resolveProps,
    resolveThemeProps,
    isMobile,
    theme,
    isTablet,
    isDesktop,
  };
};

export default useDesignUtils;
