import React from 'react';
import styled from 'styled-components';
import Box from './Box';
import VStack from './Stack/VStack';
import Small from './Typography/Small';

import PropTypes from 'prop-types';

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox using class visually-hidden, but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const Checkbox = ({
  checked,
  label,
  name,
  onChange,
  error,
  show = true,
  ...props
}) => {
  const isError = error ? 'red' : 'green';

  return (
    <VStack space="xsmall">
      <Box tag="label" display="flex" alignItems="center">
        <Box display="inline-block" verticalAlign="middle" cursor="pointer">
          <input
            type="checkbox"
            name={name}
            className="visually-hidden"
            checked={checked}
            onChange={(event) => {
              onChange(event.target.name, event.target.checked);
            }}
            {...props}
          />
          <Box
            display={show ? 'flex' : 'none'}
            width="16px"
            height="16px"
            borderRadius="xsmall"
            isChecked={checked}
            transition="all 150ms ease"
            backgroundColor={checked ? isError : 'white'}
            borderWidth="1px"
            borderStyle="solid"
            borderColor={isError}
            cursor="pointer"
          >
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </Box>
        </Box>
        {label && (
          <Box
            marginLeft="small"
            cursor="pointer"
            margin="0 auto"
            style={{ margin: show ? '' : '0 auto' }}
          >
            {typeof label === 'function' ? label() : null}
          </Box>
        )}
      </Box>
      {error && (
        <Small tag="p" color="red" fontWeight="medium">
          {error}
        </Small>
      )}
    </VStack>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  show: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default Checkbox;
