import { atom } from 'recoil';
import localStorageEffect from './localStorageEffect';

export const initialState = {
  wallet: [],
  dataQuotation: {},
  userBalance: [],
  dataInvested: null,
  dataProjectsInvest: null,
  newInvestment: null,
};

export const dashboardState = atom({
  key: 'dashboardState',
  default: initialState,
  effects_UNSTABLE: [localStorageEffect('dashboard')],
});

export const newDashBoardDataState = atom({
  key: 'newDashBoardDataState',
  default: initialState,
});
