import { useRecoilState } from 'recoil';
import { goalDataState, goalsListState } from '../recoil/goal';

const useGoalDataRecoil = () => {
  const [goal, setGoal] = useRecoilState(goalDataState);
  const [goalsListData, setGoalListData] = useRecoilState(goalsListState);

  const setGoalValues = (value) => {
    return setGoal(value);
  };

  const setGoalsList = (value) => {
    return setGoalListData(value);
  };

  const resetGoals = () => {
    return setGoalListData(null);
  };

  return {
    setGoalValues,
    goal,
    setGoalsList,
    goalsListData,
    resetGoals,
  };
};

export default useGoalDataRecoil;
