import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollMoveSection = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // This is cheating
      setTimeout(() => {
        const element = document.querySelector(hash);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 250);
    }
  }, [hash]);
};

export default useScrollMoveSection;
