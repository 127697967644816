import { useRecoilState } from 'recoil';
import { newInvesmentState, initialState } from '../recoil/investments';

const useNewInvestmentRecoil = () => {
  const [newInvestmentValues, setNewInvestmentValues] = useRecoilState(
    newInvesmentState
  );

  const setNewInvestmentFormData = (values) => {
    setNewInvestmentValues((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
  };

  const resetNewInvestMent = () => {
    setNewInvestmentValues(initialState);
  };

  return {
    setNewInvestmentFormData,
    resetNewInvestMent,
    newInvestmentValues,
  };
};

export default useNewInvestmentRecoil;
