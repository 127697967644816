import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import styled, { css } from 'styled-components';
import useDesignUtils from '../../../hooks/useDesignUtils';

const HStack = ({
  children,
  tag,
  alignItems,
  marginBottom,
  marginTop,
  padding,
  horizontalCenter,
  space,
  justifyContent = undefined,
  direction = 'row',
  ...restProps
}) => {
  const { resolveThemeProps } = useDesignUtils();
  const spaceSizing = resolveThemeProps('scale', space);

  return (
    <View
      tag={tag}
      display="flex"
      flexDirection={direction === 'reverse' ? 'row-reverse' : direction}
      alignItems={alignItems}
      space={spaceSizing}
      marginBottom={marginBottom}
      marginTop={marginTop}
      padding={padding}
      justifyContent={horizontalCenter ? 'center' : justifyContent}
      {...restProps}
    >
      {children}
    </View>
  );
};

const View = styled(Box)`
  ${setCollapse}
`;

function setCollapse({ space }) {
  if (space) {
    return css`
      & > :not(style) ~ :not(style) {
        margin-inline-start: ${space};
      }
    `;
  }
}

HStack.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  alignItems: PropTypes.string,
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  padding: PropTypes.string,
  horizontalCenter: PropTypes.bool,
  space: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  justifyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default HStack;
