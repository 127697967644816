export const HomeSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    {
      '@type': 'Question',
      name: '¿Qué es-AR SimplEstate y qué ofrecemos?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'Somos la primera plataforma de microinversiones orientadas al mercado inmobiliario con diferentes opciones de inversión en real estate. Nuestro desafío es-AR ser la mejor opción de ahorro e inversión para todos aquellos que busquen tanto proteger su dinero como querer obtener ganancias razonables de manera simple y segura.',
      },
    },
    {
      '@type': 'Question',
      name: '¿Cómo puedo invertir con SimplEstate?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'Para comenzar a formar parte de SimplEstate lo primero que debes hacer es-AR registrarte. Luego vas a poder elegir invertir en distintos proyectos seleccionando diferentes opciones de plazo y rentabilidad. Además nuestra plataforma te permite monitorear de forma fácil, rápida y segura para que puedas seguir de cerca la evolución.',
      },
    },
    {
      '@type': 'Question',
      name:
        '¿Cómo puedo informarme sobre la fluctuación que puede tener mi inversión mes a mes?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'Nuestra plataforma posee la funcionalidad de una cuenta personal donde se va a poder revisar el avance de la obra y la rentabilidad proyectada en torno al precio del mercado en tiempo real. Esto te brindará transparencia y seguridad.',
      },
    },
    {
      '@type': 'Question',
      name:
        '¿Cómo seleccionamos los emprendimientos y proyectos inmobiliarios?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'Nuestra selección de proyectos te brindará seguridad ya que invertimos en empresas sólidas como Fund Investment, que cuentan con una amplia trayectoria en el mercado y entregas cumplidas. Además tenemos acceso al avance del proyecto y al estado financiero.',
      },
    },
    {
      '@type': 'Question',
      name: '¿Cuáles son los beneficios de invertir en nuestra plataforma?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'Los beneficios de invertir con nosotros son seguridad, rapidez, simple y confiable. Las propiedades son nuestras y es-AR por ello que te garantizamos seguridad en cada proyecto. Además te ofrecemos un recupero rápido de la inversión a 10 meses. Ingresá ahora y registrate para seleccionar tu proyecto de inversión.',
      },
    },
  ],
});

export const ProjectsSchema = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    {
      '@type': 'Question',
      name: '¿Cómo invertir en proyectos inmobiliarios con SimplEstate?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          ' Lo primero que debes hacer para comenzar a invertir en nuestros proyectos inmobiliarios es-AR registrarte en nuestra plataforma. De esta forma vas a poder elegir diferentes emprendimientos de inversión inmobiliaria de forma simple, rápida y segura.',
      },
    },
    {
      '@type': 'Question',
      name: '¿Qué opciones de emprendimientos inmobiliarios hay disponibles?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'En SimplEstate te ofrecemos una amplia oferta y variedad de emprendimientos inmobiliarios para que puedas elegir invertir de acuerdo a tus necesidades y preferencias. Tenemos una interesante cantidad de proyectos inmobiliarios con diferente rentabilidad proyectada y plazo estimado de inversión, ubicados en Capital Federal y Buenos Aires.',
      },
    },
    {
      '@type': 'Question',
      name: '¿Qué documentación debe firmar el inversor?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'El inversor que comience a operar en SimplEstate debe firmar un acuerdo de inversión en el que destina un monto fijo en dólares para aplicar al proyecto inmobiliario seleccionado. Ese dinero no puede usarse en ningún otro proyecto que no sea el que el cliente decidió elegir en pimer lugar. ',
      },
    },
    {
      '@type': 'Question',
      name:
        '¿Cómo se asegura el inversor que va a cobrar una vez que se venda la propiedad en la que invirtió?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'Somos un fideicomiso de administración y por lo tanto el fiduciario no puede bajo ninguna instancia utilizar ese dinero para otro destino que no sea devolverlo junto con la rentabilidad al cliente. Además SimplEstate garantiza primero con la propiedad en la que se invirtió y luego con todas las propiedades restantes. ',
      },
    },
    {
      '@type': 'Question',
      name: '¿Porqué elegir invertir con SimplEstate?',
      acceptedAnswer: {
        '@type': 'Answer',
        text:
          'Somos la primera plataforma de microinversiones orientadas al mercado inmobiliario con diferentes opciones de inversión en real estate. Nos caracterizamos por la seguridad, rapidez, confiabilidad y simplicidad. Registrate en nuestra plataforma y comenzá a invertir ahora. Todas las propiedades son nuestras y es-AR por ello que te garantizamos seguridad en cada proyecto inmobiliario que eligas. ',
      },
    },
  ],
});
