import React from 'react';
import CompaniesLogoImg from '../../../../assets/images/companies-logo.svg';
import DassLogo from '../../../../assets/images/dass-logo.svg';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';
import { Box, Copy, HStack, NewPrimaryButton, VStack } from '../../../SimpleUI';

const Logo = () => {
  const { isMobile } = useDesignUtils();
  const StackDirection = isMobile ? VStack : HStack;

  return (
    <SectionLayout
      paddingY="small"
      backgroundColor="white"
      height="calc(100vh - 60px)"
    >
      <Box
        width="100%"
        paddingX="small"
        maxWidth="1200px"
        paddingY={{ mobile: 'small', desktop: 'xsmall' }}
        marginY={{ mobile: 'small', desktop: 'xsmall' }}
        margin="auto"
      >
        <StackDirection alignItems="center" space="medium" paddingX="medium">
          <VStack width={{ mobile: '100%', desktop: '50%' }} space="medium">
            <Box tag="img" src={DassLogo} />
            <Copy
              fontWeight="semibold"
              fontSize="companiesSectionTitle"
              tag="p"
            >
              Potenciá tus ingresos mensuales con Simplestate
            </Copy>
            <Copy fontSize="companiesLogoSectionText" tag="p">
              Inversiones en propiedades al alcance de todos, con{' '}
              <Copy
                fontWeight="semibold"
                fontSize="companiesLogoSectionText"
                tag="span"
              >
                beneficios exclusivos
              </Copy>{' '}
              para empleados de Dass.
            </Copy>
            <Box
              width="100%"
              maxWidth={{ mobile: '350px', desktop: '200px' }}
              margin={{ mobile: 'auto', desktop: 'none' }}
              paddingTop="xlarge"
              marginTop="xlarge"
            >
              <NewPrimaryButton type="button" size="full">
                <a href="#benefits" style={{ 'text-decoration': 'none' }}>
                  Más información
                </a>
              </NewPrimaryButton>
            </Box>
          </VStack>
          <Box
            margin={{ mobile: 'auto', desktop: 'none' }}
            padding={{ mobile: 'auto', desktop: 'small' }}
            width={{ mobile: '100%', desktop: '50%' }}
            paddingTop="large"
            alignItems="right"
          >
            <Box
              tag="img"
              src={CompaniesLogoImg}
              width={{ mobile: '100%', desktop: '603px' }}
            />
          </Box>
        </StackDirection>
      </Box>
    </SectionLayout>
  );
};
export default Logo;
