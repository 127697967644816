import { addSeconds } from 'date-fns';

// Esto no deberia ser un hook ya que carece de estados y solo son funciones, deberia ser
// un objeto con funciones en utils. Lo comento porque si lo agregamos dentro de una dependencia
// de un useEffect, por ejemplo, este se recalcula cada vez que se reenderiza el componente y hace useTokenUtils()
// ya que es una funcion normal y en cada llamado devuelve una instancia distinta de las funciones
const useTokenUtils = () => {
  const setToken = (token, expiresIn) => {
    const tokenExpiryDate = addSeconds(new Date(), expiresIn);

    localStorage.setItem('token', token);
    localStorage.setItem('expiresIn', tokenExpiryDate);
  };

  const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('invest');
  };

  const getToken = () => {
    const token = localStorage.getItem('token');

    return token;
  };

  const getExpireDate = () => {
    const expiresIn = localStorage.getItem('expiresIn');

    return new Date(expiresIn);
  };

  const setAuthHeader = (data) => {
    const token = data.login.accessToken;
    const expiresIn = data.login.expiresIn;

    setToken(token, expiresIn);
  };

  return { setToken, removeToken, getToken, getExpireDate, setAuthHeader };
};

export default useTokenUtils;
