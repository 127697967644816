import React from 'react';
import { useLocation } from 'react-router-dom';
import AccountRouter from './AccountRouter';
import AppRouter from './AppRouter';

const BaseRouter = () => {
  const location = useLocation();
  if (location.pathname.split('/')?.[2] == 'cuenta') {
    return <AccountRouter />;
  }
  return <AppRouter />;
};

export default BaseRouter;
