import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import WrapItem from '../Wrap/WrapItem';
import useDesignUtils from '../../../hooks/useDesignUtils';

const PaginationItem = ({
  children,
  isActive,
  onClick,
  pageNumber,
  isPrev,
  isNext,
}) => {
  const { theme } = useDesignUtils();
  const responsiveFontSizes = theme.fontSizes['copy'];

  return (
    <WrapItem>
      <Box
        paddingX="medium"
        paddingY="small"
        tag="button"
        backgroundColor={isActive ? 'green' : 'lightGray'}
        $color={isActive ? 'white' : 'black'}
        $fontSize={responsiveFontSizes}
        borderRadius="small"
        onClick={() => {
          if (isPrev) {
            onClick(pageNumber - 1);
            return;
          }

          if (isNext) {
            onClick(pageNumber + 1);
            return;
          }

          onClick(pageNumber);
        }}
      >
        {children}
      </Box>
    </WrapItem>
  );
};

PaginationItem.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  isPrev: PropTypes.bool,
  isNext: PropTypes.bool,
};

export default PaginationItem;
