import React from 'react';
import ButtonBase from './ButtonBase';
import PropTypes from 'prop-types';

const Tertiary = ({
  type = 'button',
  onClick,
  children,
  disabled,
  size,
  loading,
  iconStart,
  iconEnd,
  tag,
  href,
  download,
  target,
  alt,
  color = 'white',
  fontSize,
  backgroundColor = 'green',
  borderColor = 'green',
  ...restProps
}) => {
  return (
    <ButtonBase
      type={type}
      onClick={onClick}
      disabled={disabled}
      size={size}
      loading={loading}
      iconStart={iconStart}
      iconEnd={iconEnd}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      color={color}
      tag={tag}
      href={href}
      download={download}
      target={target}
      alt={alt}
      fontSize={fontSize}
      {...restProps}
      hover={{
        backgroundColor: '_purple',
        borderColor: 'white',
        color: 'white',
      }}
    >
      {children}
    </ButtonBase>
  );
};

Tertiary.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  size: PropTypes.string,
  loading: PropTypes.bool,
  iconStart: PropTypes.node,
  iconEnd: PropTypes.node,
  tag: PropTypes.string,
  href: PropTypes.string,
  download: PropTypes.bool,
  target: PropTypes.string,
  alt: PropTypes.string,
  fontSize: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
};

export default Tertiary;
