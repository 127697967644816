import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useCountry } from '../contexts/CountryContext';
import PageLoading from '../components/PageLoading';

const Account = lazy(() => import('../pages/Account'));

const AccountRouter = () => {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        <MyRoutes />
      </Switch>
    </Suspense>
  );
};

const MyRoutes = () => {
  const { countryPrefix } = useCountry();
  const location = useLocation();

  if (!countryPrefix) {
    return <PageLoading />;
  } else {
    if (location.pathname === '/') {
      return <Redirect to={countryPrefix} />;
    }
  }

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        <Route path={`${countryPrefix}/cuenta`}>
          <Account />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default AccountRouter;
