import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

const WrapItem = ({
  children,
  marginY = 'xsmall',
  marginX = 'xsmall',
  flex,
}) => {
  return (
    <Box
      tag="li"
      display="flex"
      alignItems="center"
      marginY={marginY}
      marginX={marginX}
      flex={flex}
    >
      {children}
    </Box>
  );
};

WrapItem.propTypes = {
  children: PropTypes.node.isRequired,
  marginY: PropTypes.string,
  marginX: PropTypes.string,
  flex: PropTypes.string,
};

export default WrapItem;
