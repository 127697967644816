import React from 'react';
import PropTypes from 'prop-types';
import HStack from '../Stack/HStack';
import VStack from '../Stack/VStack';
import Box from '../Box';
import Small from '../Typography/Small';

const RadioGroup = ({ children, direction, error }) => {
  const SelectedComponent = direction === 'horizontal' ? HStack : VStack;

  return (
    <Box role="radiogroup">
      <SelectedComponent space="large">{children}</SelectedComponent>
      {error && (
        <Box marginTop="small">
          <Small fontWeight="medium" color="red">
            {error}
          </Small>
        </Box>
      )}
    </Box>
  );
};

RadioGroup.propTypes = {
  direction: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
};

export default RadioGroup;
