import React from 'react';
import { Box, VStack, Copy, PrimaryCard, HStack } from '../../../SimpleUI';
import { Cell, Grid } from '@mollycule/lattice';
import SectionLayout from '../../../Home/SectionLayout';
import SupportExpertsMarketImg from '../../../../assets/images/support-experts-market.svg';
import SupportExpertsSecurityImg from '../../../../assets/images/support-experts-security.svg';
import SupportExpertsDiversificationImg from '../../../../assets/images/support-experts-diversification.svg';

import useDesignUtils from '../../../../hooks/useDesignUtils';

const OurTeam = () => {
  const { isMobile } = useDesignUtils();
  const StackDirection = isMobile ? HStack : VStack;

  const experts = [
    {
      id: 1,
      title: 'Mercado',
      initialText: 'Nuestros proyectos están en el',
      boldText: 'mercado de Estados Unidos,',
      finalText:
        'que es el más seguro en inmuebles y tiene los m2 más valorados a nivel mundial.',
      cover: SupportExpertsMarketImg,
    },
    {
      id: 2,
      title: 'Seguridad',
      initialText: 'Solo ',
      boldText: 'trabajamos con desarrolladores de primer nivel ',
      finalText: 'y más reconocidas dentro del mercado.',
      cover: SupportExpertsSecurityImg,
    },
    {
      id: 3,
      title: 'Diversificación',
      initialText: 'Sólo',
      boldText: 'adquirimos un 10% del desarrollo,',
      finalText: 'con el objetivo de tener diversificados tus ahorros. ',
      cover: SupportExpertsDiversificationImg,
    },
  ];

  return (
    <SectionLayout id="whySimpleState" backgroundColor="white">
      <Box
        width="100%"
        paddingX="medium"
        maxWidth="1200px"
        paddingY={{ mobile: 'medium', desktop: 'xxxxlarge' }}
        marginY={{ mobile: 'medium', desktop: 'xxxxlarge' }}
        margin="auto"
      >
        <Grid
          width="100%"
          maxWidth="1150px"
          mx="auto"
          cols={{ md: 12, xs: 12 }}
          alignItems="center"
        >
          <Cell x-span={{ sm: '10', md: '12', xs: '12' }}>
            <Box marginY="xxlarge" marginX="small" tag="section">
              <Copy fontWeight="bold" fontSize="supportOurTeamTitle">
                Nuestro equipo de expertos, y su análisis a la hora de
                seleccionar inmuebles
              </Copy>
            </Box>
          </Cell>
          {experts.map((article, i) => {
            return (
              <Cell x-span={{ xs: '12', md: '4' }} key={i} margin="10px 10px">
                <Box
                  boxShadow="medium"
                  borderRadius="medium"
                  overflow="hidden"
                  tag="article"
                  width={isMobile ? 'auto' : '353px'}
                  height={
                    isMobile
                      ? i == 1
                        ? '150px'
                        : i == 2
                        ? '130px'
                        : '170px'
                      : '354px'
                  }
                >
                  <StackDirection
                    space="large"
                    padding={isMobile ? 'large' : 'xlarge'}
                  >
                    <Box
                      tag="img"
                      src={article.cover}
                      width={isMobile ? '65px' : '80px'}
                      height={isMobile ? '65px' : '80px'}
                      className="img-cover"
                    />

                    <Box tag="header" minHeight={isMobile ? '190px' : '251px'}>
                      <VStack>
                        <Copy
                          tag="h2"
                          fontWeight="semibold"
                          fontSize="midSizeTitle"
                          textAlign="left"
                          color="_lightPurple"
                          paddingY={isMobile ? 'xxsmall' : 'xxsmall'}
                        >
                          {article.title}
                        </Copy>

                        <Copy fontSize="bigCopy" tag="p" textAlign="left">
                          {article?.initialText}{' '}
                          <Copy
                            fontWeight="semibold"
                            fontSize="bigCopy"
                            tag="span"
                          >
                            {article?.boldText}{' '}
                          </Copy>
                          {article?.finalText}
                        </Copy>
                      </VStack>
                    </Box>
                  </StackDirection>
                </Box>
              </Cell>
            );
          })}
        </Grid>
      </Box>
    </SectionLayout>
  );
};
export default OurTeam;
