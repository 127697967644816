import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BASE_URL } from '../api';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { HomeSchema, ProjectsSchema } from '../helpers/dataMarkupSchema';
import { useCountry } from '../contexts/CountryContext';

const Head = ({
  title = 'Descubrí nuestros proyectos de inversión en real estate',
  description = '¿Querés invertir en Real Estate? Encontrá una amplia variedad de proyectos inmobiliarios en Simplestate. Contamos con inversiones a medida para vos. Ingresá ahora.',
  children,
  imageUrl,
}) => {
  const { pathname } = useLocation();
  const { countryPrefix } = useCountry();

  const langByCountry = {
    '/ar': { hrefLang: 'es-ar', country: 'Argentina' },
    '/mx': { hrefLang: 'es-mx', country: 'México' },
  };

  const url = `${BASE_URL}${pathname ? pathname : ''}`;
  const fullTitle = `${title} | Simplestate ${langByCountry[countryPrefix].country}`;
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <>
      <Helmet>
        <title>{fullTitle}</title>

        <link rel="canonical" href={url} />
        <link
          rel="alternate"
          href={url}
          hrefLang={langByCountry[countryPrefix].hrefLang}
        />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content={description} />

        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta
          httpEquiv="content-language"
          content={langByCountry[countryPrefix].hrefLang}
        />
        <meta
          property="og:image"
          content={imageUrl ? imageUrl : BASE_URL + '/isologo.jpg'}
        />
        {pathname === '/' && (
          <script type="application/ld+json">{HomeSchema}</script>
        )}
        {pathname === `${countryPrefix}/proyectos-inmobiliarios/` && (
          <script type="application/ld+json">{ProjectsSchema}</script>
        )}
      </Helmet>
      {children}
    </>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default Head;
