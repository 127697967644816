import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import * as Yup from 'yup';
import { isBoolean } from 'lodash';
import Resizer from 'react-image-file-resizer';

/**
 *
 * @param {Number | String} dollars
 * @param {Number} minimumFractionDigits
 * @param {String} currencyType
 * @returns {String}
 */
export const amountFormat = (dollars = 0, currencyType = 'ARS') => {
  const pesosTypes = ['ARS', '$', 'pesos', 'ars', '2'];
  const dollarTypes = ['U$S', 'USD', 'usd', '1'];
  const mexicanTypes = ['MXN', '$', 'mxn', '3'];

  const allowTypes = [...dollarTypes, ...pesosTypes, ...mexicanTypes];

  const isValidName = allowTypes
    .flat()
    .some((types) => types.includes(currencyType));

  if (!isValidName) {
    throw new Error(`Valid currencyType: ${allowTypes.join(', ')}`);
  }

  const parseAmount = (amount) => {
    if (typeof amount === 'string') {
      if (amount.length === 0) {
        return 0;
      }

      const parsedValue = parseFloat(amount);

      return parsedValue;
    }

    return amount < 0 ? amount * -1 : amount;
  };

  const getCurrency = () => {
    const parsedCurrency = currencyType.toString();

    const isPesos = pesosTypes.some((item) => item.includes(parsedCurrency));
    const isMxn = mexicanTypes.some((item) => item.includes(parsedCurrency));

    return {
      currency: isPesos ? 'ARS' : isMxn ? 'MXN' : 'USD',
      format: 'en-US',
      currencyDisplay: 'code',
    };
  };

  const value = parseAmount(dollars);

  const { currency, format, currencyDisplay } = getCurrency();

  const result = new Intl.NumberFormat(format, {
    style: 'currency',
    currency,
    minimumFractionDigits: dollars % 1 ? 2 : 0,
    currencyDisplay,
  }).format(value);

  return result;
};

export const getLayoutActiveColor = (isActive) =>
  isActive ? '#1F1646' : '#D8D8D8';

export const pluck = (field) => (obj) => obj[field];

export const fileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });
};

export const fileResize = async (file, type = 'base64') => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      700,
      700,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      type,
      300,
      300,
    );
  });
};

export const bytesToKb = (bytes) => parseInt((bytes / 1024).toFixed(0));

export const handleGraphqlError = (errorObject) => {
  const defaultError =
    'Ha ocurrido un error, por favor inténtelo de nuevo más tarde.';

  if (errorObject?.graphQLErrors) {
    const [parsedErrors] = errorObject?.graphQLErrors?.map(
      ({ message }) => message,
    );

    return {
      status: 'error',
      message: parsedErrors || defaultError,
    };
  }

  if (errorObject?.networkError?.result?.errors?.length > 0) {
    const [parsedErrors] = errorObject.networkError?.result?.errors.map(
      ({ message }) => message,
    );

    return {
      status: 'error',
      message: parsedErrors || defaultError,
    };
  }

  return {
    status: 'error',
    message: defaultError,
  };
};

export const withErrorHandler = async (fn) => {
  try {
    const response = await fn();
    return response;
  } catch (error) {
    if (error.networkError) {
      return handleGraphqlError(error);
    }

    if (error.message) {
      return {
        status: 'error',
        message: error.message,
      };
    }

    return error;
  }
};

export const formatDate = (formatting, date) => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;

  return format(new Date(parsedDate), formatting, {
    locale: es,
  });
};

export const investCalculationValidationSchemaPesos = (
  monthSelected,
  project,
) => {
  let properties = project?.properties?.availability?.find(
    (value) => value.months === monthSelected?.months,
  );
  return Yup.object().shape({
    investValue: Yup.number()
      .min(
        properties?.inversion_min ? properties?.inversion_min : 0,
        `Mínimo a invertir ${properties?.inversion_min}`,
      )
      .max(
        properties?.inversion_max ? properties?.inversion_max : 0,
        `Máximo a invertir ${properties?.inversion_max}`,
      )
      .required('Requerido'),
    month: Yup.object().required('Requerido'),
  });
};

export const investCalculationValidationSchema = (isFixedTerm, isSubtype) => {
  if (isFixedTerm) {
    return Yup.object().shape({
      investValue: isSubtype
        ? Yup.number().required('Requerido')
        : Yup.object().required('Requerido'),
      month: Yup.object().required('Requerido'),
    });
  }

  return Yup.object().shape({
    investValue: Yup.number().required('Requerido'),
  });
};

export const investDashboardValidationSchema = (type) => {
  if (type) {
    return Yup.object().shape({
      currency: Yup.object().required('Requerido'),
      modality: Yup.object().required('Requerido'),
      cupon: Yup.object(),
      walletAmount: Yup.number()
        .when(
          ['currency', 'modality', 'cupon'],
          (currency, modality, cupon, schema) => {
            const walletValue = type ? parseFloat(walletAmount.value) : 0;
            const modalityAmount = modality?.amount;
            const isARS = currency?.value === 2;
            const cuponAmount = cupon?.amount;
            if (walletValue > modalityAmount) {
              return schema.test({
                test: (amount) => modalityAmount > amount,
                message: `El importe a invertir no puede ser mayor al saldo disponible`,
              });
            }

            const min = isARS ? 2000 : 4;
            const cuponInvestMin =
              cupon?.amount?.[isARS ? 'inv_min_ars' : 'inv_min_usd'];

            const valueCupon = cuponAmount?.[isARS ? 'ars' : 'usd'] || 0;

            if (
              cupon !== undefined &&
              amountInvest.value === '' &&
              walletValue < cuponInvestMin
            ) {
              return schema.test({
                test: (amount) => amount >= cuponInvestMin,
                message: isARS
                  ? `Para usar el cupon la inversión mínima es ARS ${cuponInvestMin}`
                  : `Para usar el cupon la inversión mínima es U$S ${cuponInvestMin}`,
              });
            }

            const amountInvest2 = isNaN(parseFloat(amountInvest.value))
              ? 0
              : parseFloat(amountInvest.value);
            const totalInvestment =
              walletValue + amountInvest2 + parseFloat(valueCupon);

            if (totalInvestment < min) {
              return schema.test({
                test: (amount) => amount >= min,
                message: isARS
                  ? 'El importe a cargar no puede ser menor a ARS 2000'
                  : 'El importe a cargar no puede ser menor a U$S 4',
              });
            }
          },
        )
        .required('Requerido'),
      amountInvest: Yup.number().when(
        ['currency', 'walletAmount', 'cupon'],
        (currency, walletAmount, cupon, schema) => {
          const isARS = currency?.value === 2;
          const min = isARS ? 2000 : 4;

          const cuponAmount = cupon?.amount?.[isARS ? 'ars' : 'usd'] || 0;
          const cuponInvestMin =
            cupon?.amount?.[isARS ? 'inv_min_ars' : 'inv_min_usd'];

          if (walletAmount > 0 && amountInvest.value === '') {
            return;
          }

          const a = isNaN(parseFloat(amountInvest.value))
            ? 0
            : parseFloat(amountInvest.value);

          const totalAmount = a + walletAmount;

          if (totalAmount < cuponInvestMin) {
            const message = isARS
              ? `Para usar el cupon la inversión mínima es ARS ${cuponInvestMin}`
              : `Para usar el cupon la inversión mínima es U$S ${cuponInvestMin}`;
            return schema.test({
              test: (amount) => amount >= cuponInvestMin,
              message,
            });
          }

          if (totalAmount + cuponAmount >= min) {
            return;
          }

          const message = isARS
            ? 'El importe a cargar no puede ser menor a ARS 2000'
            : 'El importe a cargar no puede ser menor a U$S 4';

          return schema.test({
            test: (amount) => totalAmount >= min,
            message,
          });
        },
      ),
    });
  }

  return Yup.object().shape({
    currency: Yup.object().required('Requerido'),
    cupon: Yup.object(),
    amountInvest: Yup.number()
      .when(['currency', 'cupon'], (currency, cupon, schema) => {
        let min = currency?.value === 2 ? 2000 : 4;
        let cuponInvestMin =
          cupon?.amount?.[
            currency?.value === 2 ? 'inv_min_ars' : 'inv_min_usd'
          ];
        if (parseFloat(amountInvest.value) < cuponInvestMin) {
          return schema.test({
            test: (amount) => amount >= cuponInvestMin,
            message:
              currency?.value === 2
                ? `Para usar el cupon la inversión mínima es ARS ${cuponInvestMin}`
                : `Para usar el cupon la inversión mínima es U$S ${cuponInvestMin}`,
          });
        }
        return schema.test({
          test: (amount) => amount >= min,
          message:
            currency?.value === 2
              ? 'El importe a cargar no puede ser menor a ARS 2000'
              : 'El importe a cargar no puede ser menor a U$S 4',
        });
      })
      .required('Requerido'),
  });
};

export const investmentCurrencies = (currencies) => {
  let availableCurrencies = [];
  const criptoCurrency = [{ label: 'USDT', value: 'usd' }];

  currencies.map((item) => {
    if (item.currency === 'USD') {
      availableCurrencies.push({
        label: 'USD',
        value: 'usd',
      });
    } else if (item.currency === 'ARS') {
      availableCurrencies.push({ label: 'PESOS', value: 'ars' });
    }
    if (item.currency === 'USDT') {
      availableCurrencies = criptoCurrency;
    }
  });
  return availableCurrencies;
};

export const defaultCurrencies = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'PESOS',
    value: 'ars',
  },
];

// Project type filter IDs
export const projectTypes = [
  {
    id: 1,
    filterId: '20',
    name: 'Renta Final',
    tagBackgroundColor: 'rentalFinal',
    investmentFilterId: '34',
    hexaColor: '#CDF887',
    productSlug: 'renta-final',
  },
  {
    id: 2,
    filterId: '21',
    name: 'Acuerdo Fijo Inmobiliario',
    tagBackgroundColor: 'fijo',
    investmentFilterId: '50',
    hexaColor: '#FFC85B',
    productSlug: '',
  },
  {
    id: 3,
    filterId: '22',
    name: 'Liquidez Garantizada',
    tagBackgroundColor: 'liquidez',
    investmentFilterId: '36',
    hexaColor: '#9FC7E8',
    productSlug: '',
  },
  {
    id: 4,
    filterId: '23',
    name: 'Renta Mensual',
    tagBackgroundColor: 'rentaAlquiler',
    investmentFilterId: '37',
    hexaColor: '#FF9061',
    productSlug: 'renta-mensual',
  },
  {
    id: 5,
    filterId: '53',
    name: 'Retiro Flex',
    tagBackgroundColor: 'liquidez',
    investmentFilterId: '51',
    hexaColor: '#9FC7E8',
    productSlug: 'retiro-flex',
  },
  {
    id: 6,
    filterId: '53',
    name: 'Mensual Exterior',
    tagBackgroundColor: 'mensualExterior',
    investmentFilterId: '51',
    hexaColor: '#FFAD0C',
    productSlug: 'mensual-exterior',
  },
];

export const InvestmentType = {
  ahorro: { name: 'ahorro', color: 'primary' },
  metas: { name: 'metas', color: '_lightPurple' },
};

export const projectsStateTypes = [
  { id: 1, investmentFilterId: '38', name: 'Más recientes' },
  { id: 2, investmentFilterId: '33', name: 'Más antiguas' },
];

/**
 * Convert a Boolean value to string that is used in forms
 * @param {Boolean} value
 * @returns { String | null }
 */
export const convertLegalPerson = (value) => {
  if (isBoolean(value)) {
    if (value === false) {
      return '0';
    } else {
      return '1';
    }
  }

  if (value === null) {
    return null;
  }

  return null;
};

export const getProjectStatus = (apiStatus, matchStatus) => {
  const statusWords = apiStatus?.split(' ');

  const projectStatus = statusWords?.some((el) => [matchStatus].includes(el));

  return projectStatus;
};

export const renderProfitabilityValue = (values) => {
  const { isCompleted, value, finalProfitability } = values;
  if (isCompleted) {
    return finalProfitability ? `${finalProfitability}%` : '-';
  }

  return value || '-';
};

export const renderTermsValue = (values) => {
  const { isCompleted, finalTerm, estimatedInvestmentTerm } = values;

  if (isCompleted) {
    return finalTerm || '-';
  }

  return estimatedInvestmentTerm || '-';
};

export const mapInvestmentTypeColors = (id) => {
  switch (id) {
    case 2: {
      return 'fijo';
    }

    case 3: {
      return 'liquidez';
    }

    case 4: {
      return 'rentaAlquiler';
    }

    case 1:
    default: {
      return 'rentalFinal';
    }
  }
};

export const resolveLimitsValues = (limits, currency) => {
  let min;
  let max;

  if (currency === '1' || currency === 1) {
    min = limits?.minUSD;
    max = limits?.maxUSD;
  }

  if (currency === '2' || currency === 2) {
    min = limits?.minARS;
    max = limits?.maxARS;
  }

  if (currency === '3' || currency === 3) {
    min = limits?.minMX;
    max = limits?.maxMX;
  }

  return {
    min,
    max,
  };
};

export const resolveLimitError = (
  limits,
  selectedCurrency,
  amount,
  isCripto = false,
) => {
  const { min, max } = resolveLimitsValues(limits, selectedCurrency);

  if (amount < min) {
    return `El monto no puede ser menor a ${
      isCripto
        ? `USDT ${formatNumber(1, min)}`
        : amountFormat(min, selectedCurrency)
    }`;
  }

  if (amount > max) {
    return `El monto no puede ser mayor a ${
      isCripto
        ? `USDT ${formatNumber(1, max)}`
        : amountFormat(max, selectedCurrency)
    }`;
  }

  return null;
};

export const getCurrencyId = (value) => (value === 'usd' ? 1 : 2);

export const BANK_ACCOUNTS_TYPES = [
  {
    value: 1,
    label: 'Caja de ahorro en pesos',
  },
  {
    value: 2,
    label: 'Caja de ahorro en dolares',
  },
];

export const renderTermsLabelByStatus = (status) => {
  if (status.includes('Finalizado')) {
    return 'Plazo final de inversión';
  }

  if (!status.includes('Finalizado')) {
    return 'Plazo estimado de inversion';
  }

  return '-';
};

export const resolveFontFamilyByWeight = (weight) => {
  const defaultWeight = "'Lato', sans-serif";

  const availableWeights = {
    bold: "'Lato', sans-serif",
    medium: "'Lato', sans-serif",
    regular: defaultWeight,
  };

  return defaultWeight;
};

export const resolveCurrencyIdByProjectCurrency = (
  projectCurrency,
  getString,
) => {
  const isDollar = ['USD', 'usd', 'U$S', '1', 1].includes(projectCurrency);
  const isPesosMXM = ['MXM', 'mxm', '3', 3].includes(projectCurrency);

  if (isPesosMXM) {
    return '3';
  }
  if (getString) {
    return isPesosMXM ? '3' : '';
  }

  if (getString) {
    return isDollar ? '1' : '2';
  }

  return isDollar ? 1 : 2;
};

export const sendDatalayers = (event) => {
  const data = {
    ...event,
    event: event.event || 'trackEvent',
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const resolveAmountToDollar = (currencyId, amount, currentRate) => {
  const isDollar = ['USD', 'usd', 'U$S', '1', 1].includes(currencyId);
  return isDollar
    ? parseInt(amount).toFixed(2)
    : (parseInt(amount) / parseInt(currentRate)).toFixed(2);
};

export const resolveColor = (type) => {
  if (type.toLowerCase() === 'flex' || type.toLowerCase() === 'retiro flex') {
    return '#9FC7E8';
  }
  if (
    type.toLowerCase() === 'final' ||
    type.toLowerCase() === 'renta mensual'
  ) {
    return '#FF9061';
  }

  return '#CDF887';
};

export const formatNumber = (currency, number) => {
  return new Intl.NumberFormat(currency === 1 ? 'en-EN' : 'es-AR').format(
    number,
  );
};

/**
 * Returns an object containing strings representing the amount and total amount of a transaction, formatted in the selected currency.
 *
 * @param {object} values - An object containing the values of the transaction, including walletAmount, amountInvest, and coupon.
 * @param {number} selectedCurrency - A number representing the selected currency.
 * @returns {object} - An object containing two strings: amountString representing the amount of the transaction, and totalString representing the total amount of the transaction.
 */
export const getTotal = (values, selectedCurrency) => {
  const { walletAmount, amountInvest, cupon: coupon } = values;
  let amount = [];
  let total = 0;

  const isARS = selectedCurrency === 2;
  const isMxn = selectedCurrency === 3;

  if (walletAmount) {
    const walletAmountFormatted = amountFormat(walletAmount, selectedCurrency);
    amount.push(walletAmountFormatted);
    total += parseFloat(walletAmount);
  }

  if (amountInvest) {
    const amountInvestFormatted = amountFormat(amountInvest, selectedCurrency);
    const separator = walletAmount === '' ? '' : ' + ';
    amount.push(
      walletAmount ? separator + amountInvestFormatted : amountInvestFormatted,
    );
    total += parseFloat(amountInvest);
  }

  if (coupon !== '' && coupon !== undefined) {
    let couponAmount =
      coupon.type === 'booster'
        ? (walletAmount + amountInvest) * (coupon.percentage / 100)
        : coupon.amount[isARS || isMxn ? 'ars' : 'usd'] || 0;
    const separator = walletAmount === '' && amountInvest === '' ? '' : ' + ';
    couponAmount =
      coupon.type === 'booster'
        ? couponAmount >
          coupon.amount[
            isARS || isMxn ? 'bonificacion_max_ars' : 'bonificacion_max_usd'
          ]
          ? coupon.amount[
              isARS || isMxn ? 'bonificacion_max_ars' : 'bonificacion_max_usd'
            ]
          : couponAmount
        : couponAmount;
    amount.push(separator + amountFormat(couponAmount, selectedCurrency));
    total += parseFloat(couponAmount);
  }

  const amountString = amount.join('');
  const totalString =
    total === 0 ? '' : ' : ' + amountFormat(total, selectedCurrency);

  return {
    amountString,
    totalString,
  };
};

const holidays = [
  [1, 1],
  [12, 2],
  [12, 2],
  [24, 3],
  [29, 3],
  [1, 4],
  [2, 4],
  [1, 5],
  [25, 5],
  [20, 6],
  [21, 6],
  [9, 7],
  [17, 8],
  [11, 10],
  [12, 10],
  [18, 11],
  [8, 12],
  [25, 12],
];

function isWeekend(date) {
  return date.getDay() === 0 || date.getDay() === 6;
}

function isHoliday(date, holidays) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return holidays.some(
    ([holidayDay, holidayMonth]) =>
      day === holidayDay && month === holidayMonth,
  );
}

function formatDateHoliday(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${day}/${month}/${year}`;
}

function isAfterBusinessHours(date) {
  return date.getHours() >= 18;
}

function getNextBusinessDay(date = new Date()) {
  const nextDay = new Date(date);
  nextDay.setDate(date.getDate() + 1);
  while (isWeekend(nextDay) || isHoliday(nextDay, holidays)) {
    nextDay.setDate(nextDay.getDate() + 1);
  }
  return nextDay;
}

export function addDaysToDate() {
  const currentDate = new Date();
  if (isAfterBusinessHours(currentDate)) {
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(9, 0, 0, 0);
  }
  let daysToAdd = 3;
  while (daysToAdd > 0) {
    currentDate.setDate(currentDate.getDate() + 1);
    if (isWeekend(currentDate) || isHoliday(currentDate, holidays)) {
      continue;
    }
    daysToAdd--;
  }
  return formatDateHoliday(currentDate);
}

export function formattedId(value) {
  return value.split(' ').join('-').toLowerCase() || '';
}
