import React from 'react';
import { Box, HStack, Copy, VStack } from '../../../SimpleUI';
import supportWeSelect from '../../../../assets/images/support-we-select.svg';
import SectionLayout from '../../../../components/Home/SectionLayout';
import useDesignUtils from '../../../../hooks/useDesignUtils';

const HowWeSelect = () => {
  const { isMobile } = useDesignUtils();
  const StackDirection = isMobile ? VStack : HStack;

  return (
    <SectionLayout
      paddingY="medium"
      backgroundColor="white"
      height="calc(100vh - 60px)"
    >
      <Box
        width="100%"
        paddingX={{ mobile: 'medium', desktop: 'xxxlarge' }}
        paddingY={{ mobile: 'xxxlarge', desktop: 'xxxlarge' }}
        marginY={{ mobile: 'xxxlarge', desktop: 'xxxlarge' }}
        maxWidth="1200px"
        margin="auto"
      >
        <StackDirection>
          {isMobile && (
            <Box padding="standard" width="389px">
              <Copy fontWeight="bold" fontSize="bigHeader">
                ¿Cómo seleccionamos los proyectos inmobiliarios?
              </Copy>
            </Box>
          )}

          <Box
            tag="img"
            src={supportWeSelect}
            width={{ mobile: '95%', desktop: '575px' }}
            maxWidth="600px"
          />

          <VStack width={{ mobile: '100%', desktop: '50%' }} space="medium">
            {!isMobile && (
              <Box padding="standard" width="389px">
                <Copy fontWeight="bold" fontSize="bigHeader">
                  ¿Cómo seleccionamos los proyectos inmobiliarios?
                </Copy>
              </Box>
            )}
            <Box
              boxShadow="medium"
              borderRadius="medium"
              overflow="hidden"
              tag="article"
              marginTop="20px"
              backgroundColor="white"
              position="absolute"
              bottom={isMobile ? '-70px' : '-10px'}
              left={isMobile ? '60px' : '-130px'}
              padding={isMobile ? 'standard' : 'large'}
              width={isMobile ? '289px' : '620px'}
              height={isMobile ? '155px' : '153px'}
            >
              <Copy fontSize="bigCopy">
                Nuestro equipo realiza un exhaustivo análisis para seleccionar
                las zonas con mayor potencial de revalorización.{' '}
                <Copy
                  fontWeight="semiBold"
                  fontSize="bigCopy"
                  color="_lightPurple"
                  tag="span"
                >
                  Nos aliamos con los desarrolladores más prestigiosos y
                  experimentados, garantizando inversiones seguras y rentables.
                </Copy>
              </Copy>
            </Box>
            {/* </Box> */}
          </VStack>
        </StackDirection>
      </Box>
    </SectionLayout>
  );
};
export default HowWeSelect;
