import React from 'react';
import { Box, Copy, VStack, Collapse, Title } from '../../../SimpleUI';
import { useCountry } from '../../../../contexts/CountryContext';
import SectionLayout from '../../../Home/SectionLayout';
import useDesignUtils from '../../../../hooks/useDesignUtils';

const questionsFaqsAr = [
  {
    id: 1,
    question: '¿Por qué tus ahorros siempre tiene resguardo?',
    answer: `Tus ahorros son aportados a un fideicomiso inmobiliario, que adquiere la propiedad y múltiples desarrollos inmobiliarios.El fideicomiso protege tus ahorros para que ningún factor externo ponga en riesgo tu dinero.`,
  },
  {
    id: 2,
    question: '¿Cómo hacen para generar ganancias mensuales?',
    answer: `Debido a la rotación de propiedades del portfolio, existen ventas de propiedades periódicas y de esta manera podemos brindar ganancias mensuales.`,
  },
  {
    id: 3,
    question: '¿Cómo gana dinero Simplestate?',
    answer: `Simplestate al comprar por un gran volumen de propiedades o proyectos inmobiliarios, obtiene un descuento de cada transacción, de esta manera este descuento es la ganancia de Simplestate, y lo obtiene al finalizar el plazo del proyecto inmobiliario.`,
  },
];

const questionsFaqsMx = [
  {
    id: 1,
    question: '¿Por qué tus ahorros siempre tiene resguardo?',
    answer: `Tus ahorros son aportados a un fideicomiso inmobiliario, que adquiere la propiedad y múltiples desarrollos inmobiliarios. El fideicomiso protege tus ahorros para que ningún factor externo ponga en riesgo tu dinero.`,
  },
  {
    id: 2,
    question: '¿Cómo hacen para generar ganancias mensuales?',
    answer: `Debido a la rotación de propiedades del portfolio, existen ventas de propiedades periódicas y de esta manera podemos brindar ganancias mensuales.`,
  },
  {
    id: 3,
    question: '¿Cómo gana dinero Simplestate?',
    answer: `Simplestate al comprar por un gran volumen de propiedades o proyectos inmobiliarios, obtiene un descuento de cada transacción, de esta manera este descuento es la ganancia de Simplestate, y lo obtiene al finalizar el plazo del proyecto inmobiliario.`,
  },
  {
    id: 4,
    question: '¿Cómo sé que las propiedades son reales?',
    answer: `Puedes tener la tranquilidad de que todas las propiedades presentadas en nuestra plataforma son genuinas y verificadas. Trabajamos en estrecha colaboración con prestigiosas desarrolladoras y realizamos exhaustivas diligencias para garantizar la autenticidad de cada propiedad. Además, proporcionamos información detallada y transparente en tu contrato sobre cada proyecto, incluyendo documentación legal y detalles de ubicación, para brindarte total confianza en tus inversiones.`,
  },
];

const Faqs = () => {
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();
  const questionsFaqs =
    countryPrefix === '/mx' ? questionsFaqsMx : questionsFaqsAr;

  return (
    <SectionLayout backgroundColor="white">
      <Box
        width={{ mobile: '100%', desktop: '60%' }}
        paddingX="medium"
        maxWidth="1200px"
        paddingY={{ mobile: 'medium', desktop: 'xxlarge' }}
        marginY={{ mobile: 'medium', desktop: 'xxlarge' }}
        margin="auto"
      >
        <VStack space="medium">
          <Box marginY="xxlarge">
            <Title fontWeight="bold" fontSize="bigHeader" textAlign="center">
              Preguntas frecuentes
            </Title>
          </Box>
          {questionsFaqs.map((cat) => (
            <Box key={cat.id} alignItems="left">
              <Collapse
                background="academy"
                iconVariant="plus"
                iconVariantColor="black"
                title={() => <Copy fontWeight="bold"> {cat.question}</Copy>}
              >
                <Copy dangerouslySetInnerHTML={{ __html: cat.answer }}></Copy>
              </Collapse>
            </Box>
          ))}
        </VStack>
      </Box>
    </SectionLayout>
  );
};
export default Faqs;
