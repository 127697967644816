import React from 'react';

import Company from '../components/Dass/Company';
import Head from '../components/Head';
import { Box } from '../components/SimpleUI';

const Dass = () => {
  const pageTitle = 'Dass';
  const pageDescription = 'Potenciá tus ingresos mensuales con Simplestate';

  return (
    <Head title={pageTitle} description={pageDescription}>
      <Box tag="section" position="relative">
        <Company />
      </Box>
    </Head>
  );
};

export default Dass;
