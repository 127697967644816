import { useRecoilState } from 'recoil';
import {
  dashboardState,
  initialState,
  newDashBoardDataState,
} from '../recoil/dashboard';

const useDashboardRecoil = () => {
  const [newDashboardValues, setDashboardValues] = useRecoilState(
    dashboardState,
  );
  const [dashBoardValues, setDashBoardValues] = useRecoilState(
    newDashBoardDataState,
  );

  const setDashBoardData = (values) => {
    setDashBoardValues((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
  };

  const setDashboardFormData = (values) => {
    setDashboardValues((prev) => {
      return {
        ...prev,
        ...values,
      };
    });
  };

  const resetDashboard = () => {
    setDashboardValues(initialState);
    setDashBoardValues(initialState);
  };

  return {
    setDashboardFormData,
    resetDashboard,
    newDashboardValues,
    dashBoardValues,
    setDashBoardData,
  };
};

export default useDashboardRecoil;
