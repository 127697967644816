import React, { useEffect, useState } from 'react';
import { Box, Copy, VStack, Title, HStack } from '../../../SimpleUI';
import { Cell, Grid } from '@mollycule/lattice';

import SectionLayout from '../../../Home/SectionLayout';

import SupportAlliedHernanCorralImg from '../../../../assets/images/support-allied-hernan-corral.png';
import SupportAlliedJoseAbuchaemImg from '../../../../assets/images/support-allied-jose-abuchaem.png';
import SupportAlliedMarioLedesmaImg from '../../../../assets/images/support-allied-mario-ledesma.png';
import SupportAlliedMartinMundoImg from '../../../../assets/images/support-allied-martin-mundo.png';
import SupportAlliedMartinTicineceImg from '../../../../assets/images/support-allied-martin-ticinece.png';
import SupportAlliedNabilAttarImg from '../../../../assets/images/support-allied-nabil-attar.png';
import SupportAlliedTuteMoroniImg from '../../../../assets/images/support-allied-tute-moroni.png';
import SupportAlliedVictorValleImg from '../../../../assets/images/support-allied-victor-valle.png';

import useDesignUtils from '../../../../hooks/useDesignUtils';
import { useCountry } from '../../../../contexts/CountryContext';

const allied01 = {
  id: 1,
  name: 'Hernán Corral',
  possition: 'CEO & Co-Founder Pomelo',
  image: SupportAlliedHernanCorralImg,
};
const allied02 = {
  id: 2,
  name: 'Martín Ticinese',
  possition: 'Pte. en Quilmes y Fábricas Nac. de Cerveza de Uy',
  image: SupportAlliedMartinTicineceImg,
};
const allied03 = {
  id: 3,
  name: 'Nabil Attar',
  possition: 'Veritran Founder',
  image: SupportAlliedNabilAttarImg,
};
const allied04 = {
  id: 4,
  name: 'Victor Valle',
  possition: 'CEO - Country Managing Director, Google Arg.',
  image: SupportAlliedVictorValleImg,
};
const allied05 = {
  id: 5,
  name: 'Martín Mundo',
  possition: 'Sr. Vice President Walmart US / Board Member',
  image: SupportAlliedMartinMundoImg,
};
const allied06 = {
  id: 6,
  name: 'Tute Moroni',
  possition: 'Jugador de Rugby - Puma',
  image: SupportAlliedTuteMoroniImg,
};
const allied07 = {
  id: 7,
  name: 'Mario Ledesma',
  possition: 'Jugador de Rugby - Puma',
  image: SupportAlliedMarioLedesmaImg,
};
const allied08 = {
  id: 8,
  name: 'José Abuchaem',
  possition: 'Co-Founder Tienda Nube Unicorn Experience',
  image: SupportAlliedJoseAbuchaemImg,
};

const alliedArray = [
  allied01,
  allied02,
  allied03,
  allied04,
  allied05,
  allied06,
  allied07,
  allied08,
];

const Allies = () => {
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();

  const isMx = countryPrefix === '/mx';

  return (
    <SectionLayout backgroundColor="orangeX">
      <VStack
        space="middle"
        marginTop={{ mobile: 'xsmall', desktop: 'xxxxxlarge' }}
      >
        <Box
          paddingY={{ mobile: 'xsmall', desktop: 'xsmall' }}
          marginY={{ mobile: 'xsmall', desktop: 'xsmall' }}
          margin="small"
        >
          <Title fontSize="bigHeader" textAlign="center" marginY="xsmall">
            Aliados estratégicos:
            <br />
            descubrí quiénes respaldan Simplestate
          </Title>
        </Box>
        <Box marginY="small">
          <Copy fontSize="textHeading" textAlign="center" paddingX="small">
            Deportistas argentinos de élite y grandes figuras del mundo de los
            negocios
          </Copy>
        </Box>
        <Box
          width="100%"
          paddingX="medium"
          maxWidth="1200px"
          paddingY={{ mobile: 'medium', desktop: 'large' }}
          marginY={{ mobile: 'medium', desktop: 'large' }}
          margin="auto"
        >
          <Grid
            width="100%"
            gap={isMobile ? '0' : '14px'}
            mx="auto"
            alignContent="center"
            alignItems="center"
            cols={{ xs: 12, sm: 1, md: 12 }}
          >
            {alliedArray.map((allied, i) => (
              <Cell x-span={{ xs: '12', md: '4' }} key={i} margin="10px 10px">
                <Box
                  key={i}
                  tag="main"
                  borderRadius="large"
                  width={isMobile ? '100%' : '346px'}
                  height={isMobile ? '138px' : '175px'}
                  backgroundColor="white"
                  noContentSpace
                >
                  <HStack space="medium">
                    <Box
                      tag="img"
                      position="flex"
                      src={allied.image}
                      width={isMobile ? '138px' : '175px'}
                      height={isMobile ? '138px' : '175px'}
                    />
                    <Box margin="medium">
                      <VStack>
                        <Copy
                          fontWeight="semibold"
                          fontSize="supportAlliesTitle"
                          textAlign="left"
                        >
                          {allied.name}
                        </Copy>
                        <Copy fontSize="supportAlliesSubTitle" textAlign="left">
                          {allied.possition}
                        </Copy>
                      </VStack>
                    </Box>
                  </HStack>
                </Box>
              </Cell>
            ))}
          </Grid>
        </Box>
      </VStack>
    </SectionLayout>
  );
};
export default Allies;
