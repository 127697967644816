import React from 'react';
import { Cell, Grid } from '@mollycule/lattice';

import CompaniesExpertsFinancialEducationImg from '../../../../assets/images/companies-experts-financial-education.svg';
import CompaniesExpertsPriorityAttentionImg from '../../../../assets/images/companies-experts-priority-attention.svg';
import IconGiftImg from '../../../../assets/images/icon-gift.svg';
import IconHandBagImg from '../../../../assets/images/icon-hand-bag.svg';
import IconMegaphoneImg from '../../../../assets/images/icon-megaphone.svg';
import SupportExpertsSecurityImg from '../../../../assets/images/support-experts-security.svg';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';
import { Box, Copy, HStack, VStack } from '../../../SimpleUI';

const WhySimplestate = () => {
  const { isMobile } = useDesignUtils();
  const StackDirection = isMobile ? HStack : VStack;

  const experts = [
    {
      id: 1,
      title: 'Ingresos mensuales',
      initialText: 'Recibí ',
      boldText: 'ganancias mensuales',
      finalText:
        ' que podés usar para pagar gastos fijos como expensas, servicios y gimnasio.',
      cover: IconHandBagImg,
    },
    {
      id: 2,
      title: 'Inversión segura',
      initialText: 'Tenemos más de 100 propiedades en EE.UU y Uruguay, ',
      boldText: 'garantizando una inversión segura y rentable.',
      finalText: '',
      cover: SupportExpertsSecurityImg,
    },
    {
      id: 3,
      title: 'Beneficios exclusivos',
      initialText: 'Por ser empleado de Dass, tendrás acceso a ',
      boldText: 'beneficios únicos y promociones especiales.',
      finalText: '',
      cover: IconGiftImg,
    },
    {
      id: 4,
      title: 'Programa de Referidos',
      initialText: 'Referí amigos a Simplestate y ',
      boldText: 'ganá dinero extra',
      finalText: ' por cada inversión.',
      cover: IconMegaphoneImg,
    },
    {
      id: 5,
      title: 'Educación financiera',
      initialText: 'Participá de ',
      boldText: 'webinars y charlas ',
      finalText: 'de educación financiera.',
      cover: CompaniesExpertsFinancialEducationImg,
    },
    {
      id: 6,
      title: 'Soporte personalizado',
      initialText: 'Obtené ',
      boldText: 'atención personalizada',
      finalText: ' y soporte continuo para maximizar tus inversiones.',
      cover: CompaniesExpertsPriorityAttentionImg,
    },
  ];

  return (
    <SectionLayout id="whySimpleState" backgroundColor="white">
      <Box
        width="100%"
        paddingX="medium"
        maxWidth="1200px"
        paddingY={{ mobile: 'medium', desktop: 'xxxxlarge' }}
        marginY={{ mobile: 'medium', desktop: 'xxxxlarge' }}
        margin="auto"
      >
        <Box padding="small" marginY="large" tag="section">
          <Copy fontWeight="bold" fontSize="bigHeader">
            Beneficios exclusivos
            <br />
            para empleados
          </Copy>
        </Box>
        <Grid
          width="100%"
          mx="auto"
          gap={{ xs: '40px', md: '52px' }}
          cols={12}
          alignItems="center"
        >
          {experts.map((article) => {
            return (
              <Cell
                x-span={{ xs: '12', md: '4' }}
                key={article.id}
                width={{ xs: 'calc(100vw - 30px)', md: 'auto' }}
              >
                <Box
                  boxShadow="medium"
                  borderRadius="medium"
                  overflow="hidden"
                  tag="article"
                  verticalAlign="middle"
                  height={{ mobile: 'auto', desktop: '400px' }}
                >
                  <StackDirection
                    space="large"
                    padding={isMobile ? 'large' : 'xlarge'}
                  >
                    <Box
                      tag="img"
                      src={article.cover}
                      height={isMobile ? 'auto' : '80px'}
                      width={isMobile ? '65px' : 'auto'}
                    />

                    <VStack display="inline-block" verticalAlign="middle">
                      <Copy
                        tag="h2"
                        fontWeight="semibold"
                        fontSize="midSizeTitle"
                        textAlign="left"
                        color="_lightPurple"
                        maxWidth={{ mobile: 'auto', desktop: '10ch' }}
                        paddingY={isMobile ? 'xxxsmall' : 'xxsmall'}
                        marginBottom={!isMobile ? 'large' : 'small'}
                        lineHeight="1"
                      >
                        {article.title}
                      </Copy>

                      <Copy fontSize="bigCopy" tag="p" textAlign="left">
                        {article?.initialText}
                        <Copy
                          fontWeight="semibold"
                          fontSize="bigCopy"
                          tag="span"
                        >
                          {article?.boldText}
                        </Copy>
                        {article?.finalText}
                      </Copy>
                    </VStack>
                  </StackDirection>
                </Box>
              </Cell>
            );
          })}
        </Grid>
      </Box>
    </SectionLayout>
  );
};

export default WhySimplestate;
