import useTokenUtils from './useTokenUtils';
import { useRecoilState } from 'recoil';
import { authState } from '../recoil/auth';

const useAuthRecoil = () => {
  const { removeToken } = useTokenUtils();
  const [auth, setAuth] = useRecoilState(authState);

  const handleAuthData = (data) => {
    setAuth((prev) => ({
      ...prev,
      isAuth: true,
      user: {
        ...data,
      },
    }));
  };

  const handleUpdateProfile = (data) => {
    setAuth((prev) => ({
      ...prev,
      user: {
        ...data,
      },
    }));
  };

  const logOut = () => {
    removeToken();

    setAuth((prev) => ({
      ...prev,
      isAuth: false,
      user: {},
      provider: '',
    }));
  };

  // Save provider open modal welcome first time register
  const welcomeModal = (data) => {
    setAuth((prev) => ({
      ...prev,
      provider: data,
    }));
  };

  return {
    handleAuthData,
    logOut,
    auth,
    handleUpdateProfile,
    welcomeModal,
  };
};

export default useAuthRecoil;
