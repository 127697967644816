import React from 'react';
import SelectBase from './SelectBase';
import PropTypes from 'prop-types';

const Select = ({
  options,
  name,
  value,
  label,
  onChange,
  error,
  size,
  placeholder,
  loading,
  disabled,
  borderWidth,
  ...restProps
}) => {
  return (
    <SelectBase
      name={name}
      value={value}
      label={label}
      onChange={onChange}
      error={error}
      size={size}
      placeholder={placeholder}
      loading={loading}
      options={options}
      disabled={disabled}
      borderWidth={borderWidth}
      {...restProps}
    />
  );
};

Select.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  borderWidth: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Select;
