import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Box } from '../SimpleUI';
import styled from 'styled-components';

import property_mx from '../../assets/images/property_mx.png';

export const ImageWrapper = styled(Box)`
  margin-top: -15px;
  height: 329px;
`;

const PropertiesCarousel = ({ project = [] }) => {
  return (
    <ImageWrapper position="relative">
      <Box overflow="hidden">
        {project?.length === 0 ? (
          <Box>
            <Box
              tag="img"
              src={property_mx}
              height="250px"
              borderRadius="medium"
              width="100%"
              maxWidth={{
                mobile: '100%',
                desktop: '100%',
              }}
              objectFit="cover"
              alt={project.name}
            />
          </Box>
        ) : (
          <Box variant="light">
            <Slider
              {...{
                dots: false,
                autoplay: false,
                autoplaySpeed: 6000,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
              }}
            >
              {project.map((slider, index) => (
                <Box
                  key={index}
                  tag="img"
                  src={slider}
                  height="287px"
                  width="100%"
                  alt={project.name}
                  objectFit="cover"
                ></Box>
              ))}
            </Slider>
          </Box>
        )}
      </Box>
    </ImageWrapper>
  );
};

PropertiesCarousel.propTypes = {
  project: PropTypes.object.isRequired,
};

export { PropertiesCarousel };
