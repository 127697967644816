import React from 'react';
import { Box, Copy, VStack, HStack, NewPrimaryButton } from '../../../SimpleUI';
import CompaniesLogoImg from '../../../../assets/images/companies-logo.svg';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';

const Logo = () => {
  const { isMobile } = useDesignUtils();
  const StackDirection = isMobile ? VStack : HStack;

  return (
    <SectionLayout
      paddingY="small"
      backgroundColor="white"
      height="calc(100vh - 60px)"
    >
      <Box
        width="100%"
        paddingX="small"
        maxWidth="1200px"
        paddingY={{ mobile: 'small', desktop: 'xsmall' }}
        marginY={{ mobile: 'small', desktop: 'xsmall' }}
        margin="auto"
      >
        <StackDirection alignItems="center" space="medium" paddingX="medium">
          <VStack width={{ mobile: '100%', desktop: '85%' }} space="medium">
            <Copy
              fontWeight="semibold"
              fontSize="companiesLogoSectionLogo"
              tag="h1"
              style={{ textWrap: 'balance' }}
            >
              LOGO
            </Copy>
            <Copy
              fontWeight="semibold"
              fontSize="companiesSectionTitle"
              tag="p"
            >
              Simplestate empresas
            </Copy>
            <Copy fontSize="companiesLogoSectionSubtitle" tag="p">
              ¿Querés olvidarte de un gasto todos los meses?
            </Copy>
            <Copy fontSize="companiesLogoSectionText" tag="p">
              En un clic tenes la solución con el respaldo de
              <Copy
                fontWeight="semibold"
                fontSize="companiesLogoSectionText"
                tag="span"
              >
                {' '}
                +100 proyectos
              </Copy>{' '}
              inmobiliarios.
            </Copy>
            <Box
              width="100%"
              maxWidth={{ mobile: '350px', desktop: '200px' }}
              margin={{ mobile: 'auto', desktop: 'none' }}
              paddingTop="xlarge"
              marginTop="xlarge"
            >
              <NewPrimaryButton type="button" size="full">
                <a href="#whySimpleState" style={{ 'text-decoration': 'none' }}>
                  Ver más información
                </a>
              </NewPrimaryButton>
            </Box>
          </VStack>
          <Box
            margin={{ mobile: 'auto', desktop: 'none' }}
            padding={{ mobile: 'auto', desktop: 'small' }}
            width="100%"
            paddingTop="large"
            alignItems="right"
          >
            <Box
              tag="img"
              src={CompaniesLogoImg}
              width={{ mobile: '340px', desktop: '603px' }}
              height={{ mobile: '301px', desktop: '534px' }}
            />
          </Box>
        </StackDirection>
      </Box>
    </SectionLayout>
  );
};
export default Logo;
