import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Box from './Box';
import useDesignUtils from '../../hooks/useDesignUtils';
import classNames from 'classnames';

const Component = ({
  tag = 'p',
  textAlign = 'center',
  fontSize = 'copy',
  to,
  children,
  variant,
  href,
  rel,
  target,
  textDecoration,
  ...rest
}) => {
  const { theme } = useDesignUtils();
  const responsiveFontSizes = theme.fontSizes[fontSize];

  const selectedColor = () => {
    const defaultColor = 'purple';

    const availableColors = {
      green: '_green',
      white: 'white',
      black: 'black',
      outline: 'white',
      blueLink: 'blueLink',
      _green: '_green',
    };

    return variant ? availableColors[variant] : defaultColor;
  };

  const LinkClasses = classNames('footer-nav-wrapper', {
    link__outline: variant?.includes('outline'),
    'link__outline--white': variant === 'outline-white',
  });

  const addClasses = rest.addClass ? rest.addClass : '';

  return (
    <Box
      tag={tag}
      textAlign={textAlign}
      $color={selectedColor()}
      $fontSize={responsiveFontSizes}
      className={`${LinkClasses} ${addClasses}`}
      textDecoration={textDecoration}
    >
      {React.createElement(
        to ? Link : 'a',
        {
          to: to,
          href: href,
          target: target,
          rel: rel,
        },
        children,
      )}
    </Box>
  );
};

Component.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
  variant: PropTypes.string,
  tag: PropTypes.string,
  textDecoration: PropTypes.string,
  fontSize: PropTypes.string,
  href: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
};

export default Component;
