import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Creamos el contenedor del Tooltip
const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

// Creamos el contenido del Tooltip
const TooltipText = styled.div`
  visibility: hidden;
  width: 150px;
  background-color: #fff;
  color: #000;
  text-align: left;
  border-radius: 6px;
  padding: 5px;

  /* Posicionamos el Tooltip */
  position: absolute;
  z-index: 20;
  bottom: 115%;
  left: 50%;
  margin-left: -60px; /* Centramos el tooltip */
  filter: drop-shadow(0px 0px 3.156px rgba(0, 0, 0, 0.25));

  /* Añadimos una pequeña animación */
  opacity: 0;
  transition: opacity 0.3s;

  font-size: 12px;

  /* Estilos para la flecha del Tooltip */
  ::after {
    content: ' ';
    position: absolute;
    top: 100%; /* Al borde inferior del tooltip */
    left: 50%;
    margin-left: -15px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

// Cambiamos la visibilidad cuando el usuario pasa el mouse sobre el contenedor
const Tooltip = styled(TooltipContainer)`
  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

const MyTooltip = ({ children, text }) => (
  <>
    <Tooltip>
      {children}
      <TooltipText>{text}</TooltipText>
    </Tooltip>
  </>
);

MyTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
};

export default MyTooltip;
