import { atom } from 'recoil';
import localStorageEffect from './localStorageEffect';

export const authState = atom({
  key: 'authState',
  default: {
    isAuth: false,
    user: {},
  },
  effects_UNSTABLE: [localStorageEffect('auth')],
});
