import React, { useState, useEffect } from 'react';
import {
  Box,
  Copy,
  HStack,
  VStack,
  NewPrimaryButton,
} from '../../../../components/SimpleUI';
import SectionLayout from '../../../Home/SectionLayout';
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import { useCountry } from '../../../../contexts/CountryContext';
import useDesignUtils from '../../../../hooks/useDesignUtils';

const Invest = () => {
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const history = useHistory();

  const isMx = countryPrefix === '/mx';

  const buttonLabel = isMx ? 'Empieza a invertir' : 'Empezá a invertir';
  const StackDirection = isMobile ? VStack : HStack;

  const xAxis = [
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
  ];
  const yAxis = [
    159.87,
    166.46,
    175.11,
    184.4,
    195.85,
    204.7,
    212.24,
    234.38,
    278.6,
    294.35,
    310.77,
  ];

  useEffect(() => {
    setOptions({
      chart: {
        id: 'chart',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },

      markers: {
        onClick: function (e) {
          console.log(e.target);
        },
        size: 5,
        hover: {
          sizeOffset: 3,
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          left: 30,
        },
      },
      noData: {
        text: 'Cargando...',
      },
      colors: ['#34EDC3'],
      stroke: {
        curve: 'smooth',
      },
      tooltip: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value;
          },
          style: {
            colors: '#ABABAB',
            background: '#775DD0',
            fontSize: '14px',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 400,
          },
        },
      },
      xaxis: {
        categories: xAxis,
        tooltip: {
          enabled: false,
        },
        tickAmount: 0,
        labels: {
          rotate: 0,
          formatter: function (value) {
            return value % 2 === 0 ? value : '';
          },
          style: {
            colors: '#ABABAB',
            background: '#775DD0',
            fontSize: '14px',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 400,
          },
        },
      },
    });
    setSeries([
      {
        name: 'Indice del valor de viviendas en EE UU',
        data: yAxis,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionLayout
      paddingY="medium"
      backgroundColor="white"
      height="calc(100vh - 60px)"
    >
      <Box
        width="100%"
        paddingX="medium"
        maxWidth="1200px"
        paddingY={{ mobile: 'xsmall', desktop: 'xxxlarge' }}
        marginY={{ mobile: 'xsmall', desktop: 'xxxlarge' }}
        marginTop="xsmall"
        margin="auto"
      >
        <StackDirection
          space="large"
          paddingX="medium"
          paddingY="small"
          marginTop="small"
        >
          <VStack
            width={{ mobile: '100%', desktop: '95%' }}
            marginTop="small"
            space="xxxlarge"
          >
            <Box
              width={{ mobile: '95%', desktop: '98%' }}
              margin="xsmall"
              marginTop="large"
              padding="xsmall"
            >
              {isMx && (
                <Copy fontSize="supportInvestTitle" tag="h1">
                  Inversión segura: El{' '}
                  <Copy
                    fontWeight="bold"
                    fontSize="supportInvestTitle"
                    tag="span"
                  >
                    ladrillo resguarda tus ahorros{' '}
                  </Copy>
                  a lo largo del tiempo.
                </Copy>
              )}

              {!isMx && (
                <Copy fontSize="supportInvestTitle" tag="h1">
                  El paso del tiempo muestra la seguridad en el ladrillo y{' '}
                  <Copy
                    fontWeight="bold"
                    fontSize="supportInvestTitle"
                    tag="span"
                  >
                    tus ahorros están resguardados
                  </Copy>
                </Copy>
              )}
            </Box>
            <Box
              width={{ mobile: '95%', desktop: '70%' }}
              margin={{ mobile: 'auto', desktop: 'none' }}
              maxWidth={{ mobile: '350px', desktop: '200px' }}
            >
              <NewPrimaryButton
                type="button"
                size="full"
                variant="primary"
                onClick={() =>
                  history.push(`${countryPrefix}/cuenta/crear-cuenta`)
                }
              >
                {buttonLabel}
              </NewPrimaryButton>
            </Box>
          </VStack>
          <Box
            boxShadow="medium"
            borderRadius="medium"
            margin={{ mobile: 'auto', desktop: 'medium' }}
            padding={{ mobile: 'auto', desktop: 'medium' }}
            width={{ mobile: 'auto', desktop: '1100px' }}
            height={{ mobile: '480px', desktop: '530px' }}
            paddingTop={{ mobile: 'large', desktop: 'xxxlarge' }}
            marginBottom="10px"
            paddingBottom="0px"
            alignItems="right"
            transform={isMobile ? 'translateY(0%)' : 'translateY(-10%)'}
          >
            <VStack paddingBottom="0px" marginBottom="0px">
              <Copy fontSize="copyMobile" textAlign="center" padding="medium">
                Evolución de la estabilidad de las propiedades a lo largo del
                tiempo
              </Copy>
              {options && series && (
                <Chart
                  options={options}
                  series={series}
                  type="area"
                  width="100%"
                  marginBottom="0"
                  height={isMobile ? '300px' : '374px'}
                />
              )}
              <Copy color="_lightPurple" textAlign="center">
                Fuente{' '}
                <a href="https://www.spglobal.com/spdji/en/indices/indicators/sp-corelogic-case-shiller-us-national-home-price-nsa-index/#overview">
                  S&P Global
                </a>
              </Copy>
              <Box
                tag="div"
                marginTop="20px"
                position="relative"
                top={isMobile ? '-320px' : '-415px'}
                left={isMobile ? '120px' : '320px'}
                width={isMobile ? '195px' : '195px'}
                height={isMobile ? '50px' : '50px'}
              >
                <Copy fontSize="supportInvestPill" color="_lightPurple">
                  Índice del valor de {'\n'}
                  <Copy fontSize="supportInvestPill" color="_lightPurple">
                    viviendas en EE.UU{' '}
                  </Copy>
                </Copy>
              </Box>
              <Box
                boxShadow="medium"
                borderRadius="medium"
                tag="div"
                position="relative"
                top={isMobile ? '-380px' : '-470px'}
                left={isMobile ? '220px' : '440px'}
                backgroundColor="lightPurple"
                padding="small"
                margin="small"
                width={isMobile ? '60px' : '64px'}
                height={isMobile ? '40px' : '34px'}
              >
                <Copy
                  fontWeight="bold"
                  fontSize="supportInvestPill"
                  color="_lightPurple"
                  tag="span"
                >
                  312.18
                </Copy>
              </Box>
            </VStack>
          </Box>
        </StackDirection>
      </Box>
    </SectionLayout>
  );
};
export default Invest;
