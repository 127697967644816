import { atom } from 'recoil';

export const filtersState = atom({
  key: 'filtersState',
  default: {
    filters: [],
    investments: [],
    taxonomyFiltersUsed: [],
    filtersDrawerOpen: false,
    sortDrawerOpen: false,
  },
});
