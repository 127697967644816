import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';
import { theme } from '../../theme';

const Spinner = ({ width = 50, height = 50, color = 'green' }) => {
  return (
    <Loader
      type="TailSpin"
      color={theme.colors[color]}
      height={height}
      width={width}
    />
  );
};

Spinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Spinner;
