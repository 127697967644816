import { differenceInMilliseconds, isAfter, subMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useAuthRecoil from '../hooks/useAuthRecoil';
import useNewInvesmentRecoil from '../hooks/useNewInvesmentRecoil';
import useScrollMoveSection from '../hooks/useScrollMoveSection';
import useToggleShare from '../hooks/useToggleShare';
import useTokenUtils from '../hooks/useTokenUtils';
import { theme } from '../theme';
import Footer from './Footer';
const Navbar = lazy(() => import('./Navbar'));
import NotificationsContainer from './NotificationsContainer';
import ShareProject from './ShareProject';
import { Box, Modal } from './SimpleUI';
import useGetResetFilter from '../hooks/useRecoilHook';
import useDashboardRecoil from '../hooks/useDashboardRecoil';
import useGoalDataRecoil from '../hooks/useGoalRecoils';
// import NotificationBadge from './NotificationBadge';
import { useApolloClient } from '@apollo/client';

const Layout = ({ children, backgroundColor = 'transparent' }) => {
  useScrollMoveSection();

  const { openShareModal, toggleShareModal } = useToggleShare();

  let timer;
  const { pathname, search } = useLocation();
  const history = useHistory(),
    queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const { resetNewInvestMent } = useNewInvesmentRecoil();

  const { getExpireDate } = useTokenUtils();

  const [isOpen, setOpen] = useState(false);

  const { resetFiltersRecoil } = useGetResetFilter();
  const { resetDashboard } = useDashboardRecoil();
  const { resetGoals } = useGoalDataRecoil();
  const client = useApolloClient();

  const {
    auth: { isAuth },
    logOut,
  } = useAuthRecoil();

  // Move scroll to top when you change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const country = localStorage.getItem('country');
  const handleLogout = () => {
    history.push({
      pathname: `${country}/mi-perfil`,
      search: '',
      state: {
        update: false,
      },
    });
    client.clearStore();
    resetNewInvestMent();
    resetFiltersRecoil();
    resetDashboard();
    resetGoals();
    logOut();
    history.replace(`${country}/cuenta`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resolveExpireInValidation = () => {
    const now = new Date();
    const expiresIn = getExpireDate();
    const oneMinutesBeforeExpiry = subMinutes(expiresIn, 1);

    const isExpired = isAfter(now, expiresIn);

    // Validate on every re-render if the session expires
    if (isExpired) {
      handleLogout();
      return;
    }

    // This will run the logout function one Minute before Expire time
    timer = setTimeout(
      handleLogout,
      differenceInMilliseconds(oneMinutesBeforeExpiry, now),
    );
  };

  useEffect(() => {
    if (isAuth) {
      resolveExpireInValidation();

      return () => clearTimeout(timer);
    }
  }, [resolveExpireInValidation, timer, isAuth]);

  useEffect(() => {
    if (queryParams.get('utm_campaign')) {
      localStorage.setItem('utm_campaign', queryParams.get('utm_campaign'));
    }
    if (queryParams.get('utm_source')) {
      localStorage.setItem('utm_source', queryParams.get('utm_source'));
    }
    if (queryParams.get('utm_medium')) {
      localStorage.setItem('utm_medium', queryParams.get('utm_medium'));
    }
  }, [queryParams]);

  if (/^\/cuenta($|\/)/.test(pathname)) {
    return children;
  }
  const pathSegments = pathname.split('/');
  const isDashboard =
    pathname?.includes('dashboard') || pathname?.includes('mi-perfil');

  return (
    <Suspense fallback={null}>
      <CustomNavbarWrapper
        tag="main"
        isOpen={isOpen}
        backgroundColor={backgroundColor}
      >
        {!pathSegments.includes('mercadolibre') &&
          !pathSegments.includes('cuenta') &&
          !pathSegments.includes('naranjax') &&
          !pathSegments.includes('registro') && (
            <Box position="fixed" top="0" left="0" zIndex="20" width="100%">
              <Navbar handleLogout={handleLogout} notificationIsOpen={isOpen} />
            </Box>
          )}
        {children}
        {/* {!pathSegments.includes('mercadolibre') &&
          !pathSegments.includes('dass') &&
          !pathSegments.includes('cuenta') &&
          !pathSegments.includes('naranjax') &&
          !pathSegments.includes('registro') &&
          (!isAuth || !isDashboard) && <Footer />} */}

        {isAuth && <NotificationsContainer />}

        <Modal
          isOpen={openShareModal.isOpen}
          handleClose={toggleShareModal}
          title="Compartir"
          width={500}
        >
          <ShareProject />
        </Modal>
      </CustomNavbarWrapper>
    </Suspense>
  );
};

const CustomNavbarWrapper = styled(Box)`
  padding-top: ${({ isOpen }) => (isOpen ? '110px' : '60px')};

  @media (max-width: ${theme.medias.mobile}px) {
    // padding-top: 75px;
  }
`;

// const CustomContent = styled.div`
//   @media (max-width: ${theme.medias.mobile}px) {
//     margin-top: ${({ isOpen }) => (isOpen ? "110px" : "60px")};
//   }
// `;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
};

export default Layout;
