import React from 'react';
import PropTypes from 'prop-types';
import BaseTypography from './Base';

const Title = ({
  tag = 'h1',
  textAlign,
  textTransform,
  color,
  children,
  fontSize = 'title',
  fontWeight = 'bold',
}) => {
  return (
    <BaseTypography
      tag={tag}
      textAlign={textAlign}
      textTransform={textTransform}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </BaseTypography>
  );
};

Title.propTypes = {
  tag: PropTypes.string,
  fontSize: PropTypes.string,
  textAlign: PropTypes.string,
  textTransform: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node,
};

export default Title;
