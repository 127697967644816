import { useLazyQuery } from '@apollo/client';
import { GET_COUNTRY } from '../apollo/queries';
import { handleGraphqlError } from '../helpers';

const useGetCountry = () => {
  const [
    getCountry,
    { loading, data, error: aboutError, refetch },
  ] = useLazyQuery(GET_COUNTRY, { fetchPolicy: 'no-cache' });

  const error = aboutError ? handleGraphqlError(aboutError) : undefined;

  return { loading, data, error, refetch, getCountry };
};

export default useGetCountry;
