import React from 'react';
import { Cell, Grid } from '@mollycule/lattice';

import CompaniesBloombergImg from '../../../../assets/images/companies-bloomberg.svg';
import CompaniesClarinImg from '../../../../assets/images/companies-clarin.svg';
import CompaniesCoinTelegraphImg from '../../../../assets/images/companies-coin-telegraph.svg';
import CompaniesElCronistaImg from '../../../../assets/images/companies-el-cronista.svg';
import CompaniesForbesImg from '../../../../assets/images/companies-forbes.svg';
import CompaniesInfobaeImg from '../../../../assets/images/companies-infobae.svg';
import CompaniesIPropUpImg from '../../../../assets/images/companies-iprop-up.svg';
import CompaniesLaNacionImg from '../../../../assets/images/companies-la-nacion.svg';
import { useCountry } from '../../../../contexts/CountryContext';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';
import { Box, Copy, VStack } from '../../../SimpleUI';

const mediaArray = [
  {
    id: 1,
    name: 'La Nación',
    image: CompaniesLaNacionImg,
    url:
      '/simple-academy/crearon-una-plataforma-que-permite-invertir-en-proyectos-de-nordelta-con-10-000/578',
  },
  {
    id: 2,
    name: 'Forbes',
    image: CompaniesForbesImg,
    url:
      'https://www.forbesargentina.com/innovacion/quieren-revolucionar-crowdfunding-inmobiliario-apoyandose-crypto-quienes-son-proponen-n21324',
  },
  {
    id: 3,
    name: 'Bloomberg',
    image: CompaniesBloombergImg,
    url:
      '/simple-academy/radiografia-del-inversor-millennial-en-latinoamerica/550',
  },
  {
    id: 4,
    name: 'Infobae',
    image: CompaniesInfobaeImg,
    url:
      'https://www.infobae.com/inhouse/2023/12/28/oportunidades-de-inversion-cual-es-la-plataforma-que-facilita-el-acceso-al-real-estate-con-bajos-montos-en-pesos/',
  },
  {
    id: 5,
    name: 'IPropUp',
    image: CompaniesIPropUpImg,
    url:
      'https://www.iproup.com/economia-digital/47215-la-billetera-virtual-de-propiedades-que-revoluciona-el-mercado-inmobiliario',
  },
  {
    id: 6,
    name: 'Coin Telegraph',
    image: CompaniesCoinTelegraphImg,
    url:
      '/simple-academy/la-tokenizacion-inmobiliaria-en-latam-sigue-en-expansion/565',
  },
  {
    id: 7,
    name: 'Clarin',
    image: CompaniesClarinImg,
    url: '/simple-academy/empezar-de-cero-despues-de-los-40/531',
  },
  {
    id: 8,
    name: 'El Cronista',
    image: CompaniesElCronistaImg,
    url:
      'https://www.cronista.com/infotechnology/actualidad/chau-mercado-pago-como-es-la-primera-billetera-virtual-de-propiedades-en-la-argentina/',
  },
];

const PreFooter = () => {
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();
  return (
    <SectionLayout paddingY="medium" backgroundColor="lightPurple">
      <Box
        width="100%"
        paddingX="medium"
        maxWidth="1200px"
        paddingY={{ mobile: 'large', desktop: 'xxxlarge' }}
        marginY={{ mobile: 'large', desktop: 'xxxlarge' }}
        margin="auto"
      >
        <VStack>
          <Box
            width="100%"
            maxWidth="1200px"
            paddingX="medium"
            paddingY="large"
          >
            <Box
              marginY={{ mobile: 'none', desktop: 'xxlarge' }}
              paddingBottom="xxxxlarge"
            >
              <Copy
                fontWeight="semibold"
                fontSize="companiesPreFooterSectionTitle"
                tag="h2"
              >
                Algunos de los medios que hablan de Simplestate
              </Copy>
            </Box>

            <Grid
              width={isMobile ? '100%' : '100%'}
              gap="30px 30px"
              mx="auto"
              alignContent="center"
              alignItems="left"
              cols={{ xs: 2, md: 4 }}
            >
              {mediaArray.map((media, i) => (
                <Cell
                  key={i}
                  alignItems="middle"
                  display="flex"
                  justifyContent="center"
                >
                  <Box
                    key={i}
                    tag="img"
                    position="flex"
                    src={media.image}
                    cursor="pointer"
                    maxWidth={{
                      mobile: i % 2 === 0 ? '100%' : '100%',
                      desktop: '80%',
                    }}
                    minWidth={{
                      mobile: i % 2 === 0 ? '50%' : '50%',
                      desktop: '80%',
                    }}
                    paddingY="medium"
                    marginY="medium"
                    onClick={() =>
                      media.url.startsWith('http')
                        ? window.open(media.url, '_blank')
                        : window.open(`${countryPrefix}${media.url}`, '_blank')
                    }
                  />
                </Cell>
              ))}
            </Grid>
          </Box>
        </VStack>
      </Box>
    </SectionLayout>
  );
};

export default PreFooter;
