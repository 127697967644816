import React, { useState } from 'react';
import { Box, Copy, VStack, HStack } from '../../../SimpleUI';
import useDesignUtils from '../../../../hooks/useDesignUtils';

import SectionLayout from '../../../Home/SectionLayout';

import CompaniesBenefits01Img from '../../../../assets/images/companies-benefits-01.webp';
import CompaniesBenefits02Img from '../../../../assets/images/companies-benefits-02.webp';
import CompaniesBenefits03Img from '../../../../assets/images/companies-benefits-03.webp';
import CompaniesBenefits04Img from '../../../../assets/images/companies-benefits-04.webp';

const item01 = {
  id: 1,
  key: '01',
  title: 'Ahorrar es ',
  boldTitle: 'fácil y rapido',
  subTitle:
    'El monto mínimo de inversión es ARS 30,000 o su equivalente en dólares.',
  image: CompaniesBenefits01Img,
  selected: true,
};
const item02 = {
  id: 2,
  key: '02',
  title: 'Ganancias ',
  boldTitle: 'mensuales',
  subTitle: 'Con una tasa del 8% anual en dólares',
  image: CompaniesBenefits02Img,
  selected: false,
};
const item03 = {
  id: 3,
  key: '03',
  title: 'Tu dinero ',
  boldTitle: 'disponible',
  subTitle: `Podés retirar tus ganancias mensualmente, y tu capital a los 180 días de realizada tu inversión`,
  image: CompaniesBenefits03Img,
  selected: false,
};
const item04 = {
  id: 4,
  key: '04',
  title: 'Ahorrá ',
  boldTitle: 'todos los meses',
  subTitle: 'Conocé los beneficios de ahorrar en el tiempo',
  image: CompaniesBenefits04Img,
  selected: false,
};

const Benefits = () => {
  const { isMobile } = useDesignUtils();
  const [selectedBenefits, setSelectedBenefits] = useState(item01);
  const [benefitsArray, setBenefitsArray] = useState([
    item01,
    item02,
    item03,
    item04,
  ]);

  const handleClick = (key) => {
    const newArr = [...benefitsArray];
    newArr.forEach((x) => (x.selected = false));
    const benefitSelected = newArr.find((x) => x.key === key);
    benefitSelected.selected = true;
    setBenefitsArray(newArr);
    setSelectedBenefits(benefitSelected);
  };

  return (
    <SectionLayout backgroundColor="lightPrimary">
      <Box
        width="100%"
        maxWidth="1200px"
        paddingX="medium"
        paddingY={{ mobile: 'medium', desktop: 'xxxxxlarge' }}
        marginY={{ mobile: 'medium', desktop: 'xxxxxlarge' }}
        margin="auto"
      >
        {isMobile && (
          <>
            <Box paddingX="small">
              <Copy fontSize="companiesSectionTitle" fontWeight="semibold">
                Beneficios de Simplestate
              </Copy>
              <Copy fontSize="companiesBenefitsSectionText" paddingY="large">
                Tu empresa piensa en vos, por eso te acercamos la forma de
                olvidarte todos los meses de algún gasto (gimnasio, expensas o
                el servicio de internet). 
                <Copy
                  tag="span"
                  fontSize="companiesBenefitsSectionText"
                  fontWeight="semibold"
                >
                  ¡Acércate a ese objetivo con Simplestate!
                </Copy>
              </Copy>
            </Box>
            <Box display="flex" flexDirection="row" width="100%">
              {benefitsArray.map((benefit, i) => (
                <Box
                  space="large"
                  key={i}
                  tag="div"
                  marginTop="40px"
                  margin="small"
                  width="74px"
                  height="74px"
                >
                  <Box
                    onClick={() => handleClick(benefit.key)}
                    key={i}
                    padding="medium"
                    marginY="small"
                    width="100%"
                    height="74px"
                    borderWidth="1px"
                    borderRadius="medium"
                    borderStyle="solid"
                    borderColor={
                      benefit.selected === true ? 'primary' : 'black'
                    }
                    backgroundColor={
                      benefit.selected == true ? 'primary' : 'lightPrimary'
                    }
                  >
                    <Copy
                      fontWeight="bold"
                      fontSize="companiesBenefitsSectionKey"
                      textAlign="center"
                      verticalAlign="middle"
                      color={benefit.selected == true ? 'white' : 'primary'}
                      tag="p"
                    >
                      {benefit.key}
                    </Copy>
                  </Box>
                </Box>
              ))}
            </Box>
            <VStack>
              <Box paddingX="small" paddingY="large" marginY="small">
                <Copy tag="p" fontSize="midSizeTitle" textAlign="left">
                  {selectedBenefits.title}
                  <Copy
                    tag="span"
                    fontSize="midSizeTitle"
                    fontWeight="semibold"
                    textAlign="left"
                  >
                    {selectedBenefits.boldTitle}
                  </Copy>
                </Copy>
                <Copy paddingY="small" fontSize="copy" tag="p">
                  {selectedBenefits.subTitle}
                </Copy>
              </Box>
              <Box transform="translateY(5%)">
                <Box
                  margin="small"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  tag="div"
                  backgroundColor="primary"
                  borderRadius="large"
                  width="343px"
                  height="552px"
                  transform="translateY(-6%)"
                >
                  <Box
                    tag="img"
                    transform="translateY(12%)"
                    width="264px"
                    height="554px"
                    src={selectedBenefits.image}
                  />
                </Box>
              </Box>
            </VStack>
          </>
        )}

        {!isMobile && (
          <HStack>
            <Box>
              <Box
                margin="xxlarge"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                tag="div"
                backgroundColor="primary"
                borderRadius="large"
                width="454px"
                transform="translateY(-6%)"
                height="705px"
              >
                <Box
                  tag="img"
                  alignItems="auto"
                  transform="translateY(12%)"
                  width="338px"
                  height="711px"
                  src={selectedBenefits.image}
                />
              </Box>
            </Box>
            <Box
              paddingX="midMedium"
              marginY="400px"
              margin="400px"
              bottom="200px"
            >
              <Copy fontSize="companiesSectionTitle" fontWeight="semibold">
                Beneficios de Simplestate
              </Copy>
              <Copy fontSize="companiesBenefitsSectionText" paddingY="large">
                Tu empresa piensa en vos, por eso te acercamos la forma de
                olvidarte todos los meses de algún gasto (gimnasio, expensas o
                el servicio de internet). 
                <Copy
                  tag="span"
                  fontSize="companiesBenefitsSectionText"
                  fontWeight="semibold"
                >
                  ¡Acércate a ese objetivo con Simplestate!
                </Copy>
              </Copy>

              {benefitsArray.map((benefit, i) => (
                <Box
                  space="large"
                  key={i}
                  borderColor="black"
                  borderWidth="1px"
                  borderRadius="medium"
                  tag="div"
                  marginTop="20px"
                  margin="50px"
                  width="635px"
                  height="123px"
                >
                  <Box
                    onClick={() => handleClick(benefit.key)}
                    key={i}
                    padding="large"
                    marginY="large"
                    width="95%"
                    height="123px"
                    borderWidth="1px"
                    borderRadius="large"
                    borderStyle="solid"
                    borderColor={
                      benefit.selected === true ? 'primary' : 'black'
                    }
                    backgroundColor={
                      benefit.selected == true ? 'primary' : 'lightPrimary'
                    }
                  >
                    <HStack>
                      <Copy
                        fontWeight="bold"
                        fontSize="companiesBenefitsSectionKey"
                        textAlign="center"
                        color={benefit.selected == true ? 'white' : 'primary'}
                        verticalAlign="middle"
                        paddingX="large"
                        tag="p"
                      >
                        {benefit.key}
                      </Copy>
                      <VStack
                        paddingX="small"
                        paddingY="xxxxxsmall"
                        textAlign="left"
                      >
                        <Copy tag="p" fontSize="midSizeTitle" textAlign="left">
                          {benefit.title}
                          <Copy
                            tag="span"
                            fontSize="midSizeTitle"
                            fontWeight="semibold"
                            textAlign="left"
                          >
                            {benefit.boldTitle}
                          </Copy>
                        </Copy>

                        <Copy fontSize="copy" tag="p">
                          {benefit.subTitle}
                        </Copy>
                      </VStack>
                    </HStack>
                  </Box>
                </Box>
              ))}
            </Box>
          </HStack>
        )}
      </Box>
    </SectionLayout>
  );
};

export default Benefits;
