import { BrowserRouter as Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import React from 'react';
import { CountryProvider } from './contexts/CountryContext';

import BaseRouter from './routes/BaseRouter';

const App = () => {
  const { ready } = useTranslation();
  if (!ready) return null;
  return (
    <CountryProvider>
      <Router>
        <BaseRouter />
      </Router>
    </CountryProvider>
  );
};

export default App;
