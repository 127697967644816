import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // Carga las traducciones usando http (p.ej., los archivos JSON)
  .use(LanguageDetector) // Detecta el idioma del usuario
  .use(initReactI18next) // Pasa i18n a React-i18next
  .init({
    fallbackLng: 'es-AR', // Idioma a usar si no se detecta ninguno
    react: {
      useSuspense: false,
    },
    debug: true, // Muestra información de depuración en la consola
    interpolation: {
      escapeValue: false, // No es-AR necesario escapar los valores en React
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
