import React from 'react';
import PropTypes from 'prop-types';
import WrapItem from '../Wrap/WrapItem';
import { Box } from '../../SimpleUI';

const NavItem = ({ children, fullWidth, marginX = 'xsmall' }) => {
  return (
    <Box
      tag="li"
      display="flex"
      alignItems="center"
      marginX={marginX}
      flex={fullWidth && '1'}
      borderRadius="topBorder"
      minWidth="115px"
    >
      {children}
    </Box>
  );
};

NavItem.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  marginX: PropTypes.string,
};

export default NavItem;
