import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

const SimpleCarousel = ({
  children,
  infinite = true,
  slidesToShow = 1,
  interval,
  centerMode = true,
  slidesToScroll = 1,
  className = '',
  initialSlide = 0,
  arrows = true,
  dots = false,
  responsive = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}) => {
  const settings = {
    dots,
    speed: 500,
    slidesToShow,
    infinite,
    centerMode,
    arrows,
    slidesToScroll,
    className,
    initialSlide,
    responsive,
    autoplay: interval ? true : false,
    autoplaySpeed: interval ? interval : undefined,
  };

  return <Slider {...settings}>{children}</Slider>;
};

SimpleCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  infinite: PropTypes.bool,
  centerMode: PropTypes.bool,
  slidesToShow: PropTypes.number,
  interval: PropTypes.number,
  slidesToScroll: PropTypes.number,
  className: PropTypes.string,
  arrows: PropTypes.bool,
  dots: PropTypes.bool,
  initialSlide: PropTypes.number,
  responsive: PropTypes.arrayOf(
    PropTypes.shape({
      breakpoint: PropTypes.number,
      settings: PropTypes.shape({
        slidesToShow: PropTypes.number,
        slidesToScroll: PropTypes.number,
      }),
    }),
  ),
};

export default SimpleCarousel;
